import { COINSBIT, HOLDERS, UNISWAP } from '../components/token/Config';

export default {
  more: 'Jeszcze',
  buy: 'Kup',
  news: `
  <b>Wiadomości:</b> Jesteśmy notowani na giełdzie <a href="${COINSBIT}">Coinsbit</a>!
  `,
  the: 'the',
  yield: `Standardowy, łatwy, generujący zysk inteligentny kontrakt <br /> już z ponad ${HOLDERS} posiadaczami`,
  tokenomics: `$POODL to sprawdzony, skuteczny, audytowany inteligentny kontrakt z \
    ponad ${HOLDERS} posiadaczami. Dołącz do nas i ciesz się jazdą!`,
  profitFrom: 'Zysk przez Trzymanie',
  profitDesc: 'Twój portfel rośnie pasywnie. Każda tranzakcja w sieci dodaje do twojego portfela',
  fun: 'Fun',
  community: 'Społeczność',
  supply: 'Pierwotna Ilość',
  burn: 'Spalone',
  tax: 'Podatek od Tranzakcji',
  distributed: 'Redystrybucja',
  road: 'Droga do Przodu',
  audit: 'Audyt Bezpieczeństwa Kontraku Przeszedł Pomyślnie',
  contractAudit: `Inteligentny kontrakt POODL został <br>skontrolowany i zatwierdzony przez
    <a href="https://techrate.org/">TechRate</a>`,
  haveFun: '<span>Uśmiechnij Się</span>,<br />ok?',
  communityTitle: 'W 100% prowadzony przez naszą społeczność',
  communityText: `
  $POODL to token, który jest zarządzany i obsługiwany
  wyłącznie przez jego społeczność. Mamy obecnie już ponad $ {HOLDERS} posiadaczy
  z wywarzoną dystrybucją która zachęca do posiadania i współpracy.`,
  howTo: 'Jak kupić POODL',
  downloadMeta: 'Zainstaluj <a href="https://metamask.io">MetaMask (metamask.io)</a> lub użyj twój obecny portfel',
  goToUni: `Wejdź na <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Naciśnij',
  topRight: 'u góry z prawej strony',
  add: 'Dodaj',
  clickSettings: 'Naciśnij Ustawienia',
  setSlippage: 'i ustaw poślizg na 5,00%',
  gasFees:
    'Wpisz swoją kwotę i zamień na POODL. Pamiętaj, aby mieć wystarczającą ilość ETH na opłatę tranzakcji (GAS)!',
  ayuda: 'Jesteśmy projektem społecznościowym. Jeśli potrzebujesz pomocy lub chcesz się zaangażować, dołącz do nas!',
  contract: 'Kontrakt',
  getYourLevel: 'Sprawdź Swój Poziom POODLa',
  poodlizeMe: 'POODLize Me',
};
