export const HOLDERS_BASE = 'https://token-holders.s3.amazonaws.com/export-tokenholders-for-contract-';
export const CONTRACT_V1 = '0x56a980328aee33aabb540a02e002c8323326bf36';
export const CONTRACT = '0x4a68c250486a116dc8d6a0c5b0677de07cc09c5d';
export const INFURA_ID = '84842078b09946638c03157f83405213';
export const HOLDERS = '30,000';
export const DELTA = 'https://delta.canny.io/coin-requests/p/poodle-poodl';
export const BLOCKFOLIO = 'https://blockfolio.canny.io/coin-requests/p/poodlbsc';
export const AUDIT = 'https://solidity.finance/audits/POODL/';
export const LOCKED = 'https://app.unicrypt.network/amm/pancake-v2/pair/0x12FC9D611b27E9E1B75A134f416eF071d7cbFF12';
// exchanges
export const PANCAKE_SWAP = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${CONTRACT}`;
export const UNISWAP = `https://app.uniswap.org/#/swap?outputCurrency=${CONTRACT_V1}`;
export const COINSBIT = 'https://coinsbit.io/trade/POODL_USDT';
export const WHITEBIT = 'https://whitebit.com/trade/POODL2_DECL';
export const HOTBIT = 'https://www.hotbit.io/exchange?symbol=POODL_USDT';
export const LBANK = 'https://www.lbank.info/exchange/poodl/usdt';
export const ONE_INCH = 'https://app.1inch.io/#/56/swap/BNB/POODL';
export const BAKERYSWAP = `https://www.bakeryswap.org/#/swap?outputCurrency=${CONTRACT}`;
export const SUSHISWAP = `https://app.sushi.com/swap?outputCurrency=${CONTRACT}`;
export const DOGEDEX = `https://www.dogedex.finance/#/swap?outputCurrency=${CONTRACT}`;
export const DOGEN = 'https://dogen.finance';
// dropped listings
export const ZT = 'https://www.ztb.im/exchange?coin=POODL_USDT';
export const YIHUO = 'https://www.yanbipro.cn/en_US/trade/POODL_USDT';
// charts
export const COINGECKO = 'https://www.coingecko.com/en/coins/poodl-token';
export const COINMARKETCAP = 'https://coinmarketcap.com/currencies/poodle/';
export const DEXTOOLS_BSC =
  'https://www.dextools.io/app/pancakeswap/pair-explorer/0x12fc9d611b27e9e1b75a134f416ef071d7cbff12';
  export const DEXGURU = `https://dex.guru/token/${CONTRACT}`;

export const ETHERSCAN = `https://etherscan.io/token/${CONTRACT}#balances`;
export const BSCSCAN = `https://bscscan.com/token/${CONTRACT}`;
// eslint-disable-next-line
export const REDDIT = 'https://www.reddit.com/r/PoodlTokenOfficial/';
export const DISCORD = 'https://discord.gg/MUyEGNkuCd';
export const TWITTER = 'https://twitter.com/poodletoken';
export const MEDIUM = 'https://poodl-token.medium.com/';
export const TIKTOK = 'https://www.tiktok.com/@poodltoken';
export const INSTAGRAM = 'https://www.instagram.com/poodltoken/';
export const YOUTUBE = 'https://www.youtube.com/channel/UCGSnA3XpnFvE7EY-lMJmTJw';
export const NFTS = 'https://opensea.io/collection/poodl-official-nft-collection';
// eslint-disable-next-line
export const NIKE_NFT = 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/68151844117215194953738857635352952869928418669505623510406186312749140672532';
export const COINBASE = 'https://www.coinbase.com/price/poodle';
export const DONATION_ADDRESS = '0xC0D2bEf096fFf3a4Cce3A74A5DD58C3281Fb3a03';
export const BURN_ADDRESS = '0x000000000000000000000000000000000000dead';
export const MARKETING_WALLET = '0x71bcb6ac6cf891c0801a5570ba8d62650D2C643C';
export const SPOTIFY_PLAYLIST = 'https://open.spotify.com/embed/playlist/51oZCBnoT5Ci0Akl93dJDg';
export const GRAPHCMS = 'https://api-us-east-1.graphcms.com/v2/ckn87rzshnd4801xp3bgvggjv/master';
export const MERCH_SHOP = 'https://shop.poodltoken.com';
export const ETHERSCAN_API_KEY = '438MWASVA31RH2MMHNRKMIA2UY81FE5YPP';
export const BSCSCAN_API_KEY = '3DXU2R53EWFZFRTD9XWMN1NI2EQP3RNP1Y';
export const CMC_API_KEY = 'f54dc0ef-7954-4655-83a3-d9ef56693710';
export const POODLCAST = 'https://www.youtube.com/channel/UCGSnA3XpnFvE7EY-lMJmTJw';

export const TELEGRAM = 'https://t.me/Poodl';

export const TELEGRAMS = [
  {
    key: 'Poodl',
    label: 'English (Main)',
  },
  {
    key: 'POODLGERMANY',
    label: 'German',
  },
  {
    key: 'poodl_france',
    label: 'French',
  },
  {
    key: 'POODLEDUTCH',
    label: 'Dutch',
  },
  {
    key: 'POODLTURKEY',
    label: 'Turkish',
  },
  {
    key: 'poodl_china',
    label: 'Chinese',
  },
  {
    key: 'POODLSPANISH',
    label: 'Spanish',
  },
  {
    key: 'POODL_Italia',
    label: 'Italian',
  },
  {
    key: 'POODLRUSSIAN',
    label: 'Russian',
  },
  {
    key: 'POODLVietnamese',
    label: 'Vietnamese',
  },
  {
    key: 'poodl_JP',
    label: 'Japanese',
  },
];

export const GRAPHCMS_AUTH_TOKEN = [
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0',
  '.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjEwMDUxNTksImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN',
  '0LTEuZ3JhcGhjbXMuY29tL3YyL2Nrbjg3cnpzaG5kNDgwMXhwM2JndmdnanYvbWFzdGVyIiwiaHR0c',
  'HM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWV',
  'udC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZDlkOGExM2EtNjQ0ZS00OTAxLWI1YjktNGViYmM1NTEyYj',
  'FiIiwianRpIjoiY2tvb2dtYTJnMzdoZzAxejIxdnc0YXcwaCJ9.pjisiMtvkKzwwo3fFjX_41WbOH5Wku',
  'tkBPBeMGC3bvORVdcufwfc97z1JIHU3xTni4MIz-g1iPJg6eDgBjP6gnn8XfZ1IgYZgPfmHrWfwYs28THqrLUvOGwfyaeNZl4R-KC2WVBWSuWru57MqYK',
  'l_-V6Tn9o3dVJ2Ftr1lOZz-mX1WEVGEo4r5pEYVVw7qYmMenrluaebgen5u6WDVI8iv5iuD38mNTsvjATWZKdjsJwNHCvAu6OHSCHvqrCP1hHyZ4jL4fxG',
  'S4LUg183hUCbqzxPxJ_tODF0mTBw9TY3WM9kxlexKg2-_b9O1Z_NPH8gPlLw1_9z7ZrVayHnp3JLhq_sKgRt-h55JrmgdEetlHlWn7R_X5vPiIIREIt-ohs',
  'WHQSANS7i3KbbxKKFbM8A4EJos-HtB3WkkArdTZTZSHhN89yGCC84LYtvjGqYjYDfrOa1oZZTTIeAwQBFB7ybOXSV7n2GiU_Bi9NAyhlUwJE3mMgJP2Mmzoj',
  'RKJ01ImR5x-4CcwNXpqAyGUwrHCYTCv0T_oPH0wdP5r6KtPaQYkZ7WzPrOKw1gBKnTCBtHWgGDqZxpYo03uZCEDGUM6FHbE6tgE9Pio6lJOWECwSf-VeJrJtg',
  'PyF7OeixwbLogv-lgOyePkg3AAx8r4KapDRLgttxEfKjlie8yn7Z_rvK-I',
].join('');
