import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isNumber from 'lodash/fp/isNumber';
import isNaN from 'lodash/fp/isNaN';
import { Statistic, Card, Collapse } from 'antd';
import { Link } from 'react-router-dom';

import body from '../../assets/images/meme/body.png';
import token from '../../assets/images/token.png';
import tokenOld from '../../assets/images/token-old.png';
import { isMobile } from '../../utils/utils';
import WalletConnect from '../elements/WalletConnect';
import { getBscBalance, getErcBalance } from '../../api';
import { CONTRACT, CONTRACT_V1, PANCAKE_SWAP, TELEGRAM } from '../token/Config';

const { Panel } = Collapse;

const Wallet = styled.div`
  text-align: center;
  margin: 10px 0px;
`;

const width = isMobile() ? '100%' : '600px';

const useBalance = ({ walletID, network }) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    let f = getErcBalance;
    let contract = CONTRACT_V1;
    if (network === 'BSC') {
      contract = CONTRACT;
      f = getBscBalance;
    }

    f({ walletID, contract }).then(r => {
      setBalance(r.result / 1e9);
    });
  }, [walletID]);

  return balance;
};

const Inner = ({ walletID }) => {
  const balanceV1 = useBalance({ network: 'ERC', walletID });
  const balanceV2 = useBalance({ network: 'BSC', walletID });

  if (!isNumber(balanceV2) || isNaN(balanceV2)) return null;

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Your Wallet ID</h3>
      <Wallet>{walletID}</Wallet>
      <h4 style={{ textAlign: 'center' }}>POODL Balances</h4>
      <div style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row' }}>
        <Card style={{ margin: 10, flex: 1 }}>
          <img width={100} src={tokenOld} style={{ margin: '0px auto 20px auto' }} />
          <Statistic title="POODL V1" value={balanceV1} precision={2} />
        </Card>
        <Card style={{ margin: 10, flex: 1 }}>
          <img width={100} src={token} style={{ margin: '0px auto 20px auto' }} />
          <Statistic title="POODL V2" value={balanceV2} precision={2} />
        </Card>
      </div>
      <img width={300} src={body} style={{ margin: 'auto' }} />
      <h4 style={{ textAlign: 'center' }}>Now what?</h4>
      <Collapse accordion style={{ margin: 10 }}>
        {balanceV1 === 0 && balanceV2 === 0 && (
          <>
            <Panel header="I never owned any POODL">
              <span>
                Well then, let&apos;s get started! Set up your wallet and buy some on{' '}
                <a href={PANCAKE_SWAP}>PancakeSwap</a>. Welcome!
              </span>
            </Panel>
            <Panel header="I had POODL but sold it before the snapshot">
              <span>
                Only users that held their POODL during the <i>entire</i> snapshot window (00:00 April 25th, 2021 to
                23:59 April 26th, 2021) were eligible for the airdrop. This was communicated very clearly before the
                snapshot window.
              </span>
            </Panel>
          </>
        )}
        {balanceV1 > 0 && balanceV2 === 0 && (
          <>
            <Panel header="I had POODL but sold it before the snapshot">
              <span>
                Only users that held their POODL during the <i>entire</i> snapshot window (00:00 April 25th, 2021 to
                23:59 April 26th, 2021) were eligible for the airdrop. This was communicated very clearly before the
                snapshot window.
              </span>
            </Panel>
            <Panel header="I have my POODL in WhiteBIT/Coinsbit">
              <span>
                As much as we tried, these exchanges couldn&apos;t support the airdrop. We communicated clearly that
                users that held their POODL in these exchanges had to move it out before the airdrop to be eligible.
                This was posted on all our social channels as well as our main Telegram, with a bot posting every 20
                minutes. Note that we are still actively trying to help users that had POODL V1 in these exchanges.
              </span>
            </Panel>
          </>
        )}
        {balanceV2 === 0}
        {balanceV2 > 0 && (
          <>
            <Panel header="I see my V2 tokens in my wallet">
              <span>
                Great! You succesfully received the airdrop and are using a wallet that supports BSC. You can start
                trading POODL on <a href={PANCAKE_SWAP}>PancakeSwap</a>. If you received slightly less V2 tokens than
                V1, this is due to transaction fees incurred during the airdrop.
              </span>
            </Panel>
            <Panel header="I don't see my V2 tokens in my wallet">
              <Collapse accordion>
                <Panel header="I use Coinbase Wallet">
                  <span>
                    Coinbase Wallet does not support BSC, but your tokens are safe and sound. Please set up a supported
                    wallet like <Link to="/post/metamask-add-bsc">MetaMask</Link> or{' '}
                    <Link to="/post/trust-wallet-setup">TrustWallet</Link> following these{' '}
                    <Link to="/learn">guides</Link>.
                  </span>
                </Panel>
                <Panel header="I use MetaMask">
                  <span>
                    Do not worry, your tokens are safe and sound. You probably just need to add the POODL token to
                    MetaMask. Please follow <Link to="/post/metamask-add-bsc">this guide</Link>.
                  </span>
                </Panel>
                <Panel header="I use TrustWallet">
                  <span>
                    Do not worry, your tokens are safe and sound. You probably just need to add the POODL token to
                    TrustWallet. Please follow <Link to="/post/trust-wallet-setup">this guide</Link>.
                  </span>
                </Panel>
                <Panel header="I use another wallet">
                  <span>
                    If your wallet supports BSC, simply add the POODL token ({CONTRACT}) and your balance should be
                    visible.
                  </span>
                </Panel>
              </Collapse>
            </Panel>
          </>
        )}
      </Collapse>
      <p style={{ padding: 20 }}>
        If your problem is still not resolved after using this tool, please reach out to the community on{' '}
        <a href={TELEGRAM}>Telegram</a> and we will surely be able to help you out
      </p>
    </div>
  );
};

const MigrationHelp = () => {
  return (
    <div style={{ width, margin: 'auto' }}>
      <WalletConnect buttonStyle={{ width: 'calc(100% - 20px)', margin: '20px 10px' }}>
        {({ walletID }) => {
          if (!walletID)
            return (
              <div style={{ padding: 20 }}>
                <h4>Howdy POODLer!</h4>
                <p>
                  After migration, many holders are having a hard time finding their POODL V2 tokens but we are here to
                  help. Please connect your wallet or enter it manually if you prefer to get your balances
                </p>
              </div>
            );

          return <Inner walletID={walletID} />;
        }}
      </WalletConnect>
    </div>
  );
};

export default MigrationHelp;
