import React from 'react';
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Community from '../components/sections/Community';
import Roadmap from '../components/sections/Roadmap';
import Audit from '../components/sections/Audit';
import Logos from '../components/sections/Logos';
// import Player from '../components/sections/Player';
// import Donation from '../components/sections/Donation';
import Contract from '../components/sections/Contract';
import HowTo from '../components/sections/HowTo';
import { FeaturedNfts } from '../components/sections/nfts/Nfts';
import SectionHeader from '../components/sections/partials/SectionHeader';
// import TokenWrapper from '../components/token/Token';
// import ModalAlert from '../components/elements/ModalAlert';

const Home = () => {
  return (
    <div>
      {/* <ModalAlert /> */}
      <Hero />
      <Contract />
      <Logos />
      {/* <TokenWrapper /> */}
      <div style={{margin: 'auto', padding: 'auto', display: 'flex', flexDirection: 'column'}}>
          <SectionHeader
            data={{
              title: 'Live CoinGecko Chart',
              paragraph: (
                <>
                  <span>
                    See the price live!
                  </span>
                </>
              ),
            }}
            className="center-content"
            style={{ position: 'relative', zIndex: 10 }}
          />
        <div style={{margin: 'auto', padding: 'auto',display: 'flex'}}>
          <coingecko-coin-price-chart-widget  coin-id="poodle" currency="usd" height="300" width="350" locale="en"></coingecko-coin-price-chart-widget>
        </div>
        <div style={{margin: 'auto', padding: 'auto',display: 'flex'}}>
          <coingecko-coin-converter-widget  coin-id="poodle" currency="usd" background-color="#ffffff" font-color="#4c4c4c" width="350" locale="en"></coingecko-coin-converter-widget>
        </div>
        <SectionHeader
            data={{
              title: 'POODLCast',
              paragraph: (
                <>
                  <span>
                    Stay up to date with the latest news, with the Poodl Podcast!
                  </span>
                </>
              ),
            }}
            className="center-content"
            style={{ position: 'relative', zIndex: 10 }}
          />
        <div style={{
          margin: 'auto', 
          display: 'flex', 
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: '56.25%',
        }}>
          <iframe style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }} src="https://www.youtube.com/embed/videoseries?list=PL7qEEtxbrLBJZjQi9e1h30whL7iPaV17b" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <SectionHeader
            data={{
              title: 'POODLCast',
              paragraph: (
                <>
                  <span>
                    Audio-Only version also available!
                  </span>
                </>
              ),
            }}
            className="center-content"
            style={{ position: 'relative', zIndex: 10 }}
          />
        <div>
          <iframe src="https://widget.spreaker.com/player?show_id=4935656&theme=dark&playlist=show&playlist-continuous=false&chapters-image=true&episode_image_position=left&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=false&color=ff6900" width="100%" height="350px" frameBorder="0"></iframe>
        </div>
      </div>
      <FeaturesTiles />
      <Roadmap />
      <Audit />
      {/* <Donation /> */}
      {/* <Player /> */}
      <HowTo />
      <Community />
      <FeaturedNfts />
      <Contract />
    </div>
  );
};

export default Home;
