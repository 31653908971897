import { COINSBIT, HOLDERS, UNISWAP } from '../components/token/Config';

export default {
  more: 'Di più',
  buy: 'Acquistare',
  news: `
  <b>Novità:</b> Siamo su <a href="${COINSBIT}">Coinsbit</a>!
  `,
  the: 'the',
  yield: `Contratto standard, senza attriti, che genera rendimento <br /> con più di ${HOLDERS} titolari`,
  tokenomics: `$POODL è uno smart contract di successo, verificato e sicuro, con \
    oltre ${HOLDERS} titolari. Raggiungici e ti divertirai!`,
  profitFrom: 'Guadagna dalla partecipazione',
  profitDesc: 'Il valore del tuo portafoglio aumenta ad ogni transazione',
  fun: 'Divertimento',
  community: 'Community',
  supply: 'Quantità iniziale',
  burn: 'Bruciato',
  tax: 'Tassa sulle transazioni',
  distributed: 'Distribuito',
  road: 'La tabella di marcia',
  audit: 'Verifica di sicurezza passata',
  contractAudit: `Lo smart contract di POODL è stato <br/>verificato
    e approvato da <a href="https://techrate.org/">TechRate</a>`,
  haveFun: "<span>DIVERTITI</span> un po'<br /> ok?",
  communityTitle: '100% Guidato dalla Community',
  communityText: `
  $POODL è un token che è supportato e guidato solamente dalla sua community.
  Con oltre ${HOLDERS} titolari, il token ha una distribuzione molto regolare che incentiva
  la partecipazione e la collaborazione per far avere successo a $POODL`,
  howTo: 'Come comprare POODL',
  downloadMeta: 'Scarica <a href="https://metamask.io">MetaMask (metamask.io)</a> o usa un portafoglio esistente',
  goToUni: `Vai su <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Clicca',
  topRight: "sull'angolo destro",
  add: 'Aggiungi',
  clickSettings: 'Clicca opzioni (settings)',
  setSlippage: 'e setta lo "slippage" a 5.00%',
  gasFees: "Inserisci l'importo e scambia. Ricordati di avere abbastanza ETH per il costo del gas!",
  ayuda: "Siamo un progetto guidato dalla community. Se hai bisogno d'aiuto o vuoi collaborare, unisciti a noi su",
  contract: 'Contratto',
  getYourLevel: 'Scopri il tuo Livello POODL',
  poodlizeMe: 'POODLizzami',
};
