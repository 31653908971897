import React from 'react';
import styled from 'styled-components';
import whitebit from '../../assets/images/logos/whitebit.png';
import coingecko from '../../assets/images/logos/coingecko.png';
import dextools from '../../assets/images/logos/dextools.png';
import oneInch from '../../assets/images/logos/1inch.png';
import cmc from '../../assets/images/logos/cmc.png';
import pancake from '../../assets/images/logos/pancake.png';
import hotbit from '../../assets/images/logos/hotbit.png';
import lbank from '../../assets/images/logos/lbank.png';
import dogedex from '../../assets/images/logos/dogedex.png';
import bakeryswap from '../../assets/images/logos/bakeryswap.png';
import sushiswap from '../../assets/images/logos/sushiswap.png';
import dogen from '../../assets/images/logos/dogen.png';
import { isMobile } from '../../utils/utils';
import {
  PANCAKE_SWAP,
  HOTBIT,
  COINGECKO,
  COINMARKETCAP,
  DEXTOOLS_BSC,
  LBANK,
  ONE_INCH,
  WHITEBIT,
  DOGEDEX,
  BAKERYSWAP,
  SUSHISWAP,
  DOGEN,
} from '../token/Config';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0px;
`;

const LogoLink = styled.a`
  flex: 0 0 calc(100% / ${isMobile() ? 2 : 3});
`;

const Logo = styled.img`
  image-rendering: crisp-edges;
  padding: 20px 10px;
  margin: auto;
`;

const Logos = () => {
  const logos = [
    {
      link: PANCAKE_SWAP,
      img: pancake,
    },
    {
      link: HOTBIT,
      img: hotbit,
    },
    {
      link: DEXTOOLS_BSC,
      img: dextools,
    },
    {
      link: COINGECKO,
      img: coingecko,
    },
    {
      link: COINMARKETCAP,
      img: cmc,
    },
    {
      link: ONE_INCH,
      img: oneInch,
    },
    {
      link: LBANK,
      img: lbank,
    },
    {
      link: WHITEBIT,
      img: whitebit,
    },
    {
      link: DOGEDEX,
      img: dogedex,
    },
    {
      link: BAKERYSWAP,
      img: bakeryswap,
    },
    {
      link: SUSHISWAP,
      img: sushiswap,
    },
    {
      link: DOGEN,
      img: dogen,
    },
  ].map(l => (
    <LogoLink key={l.link} href={l.link} target="_blank">
      <Logo src={l.img} />
    </LogoLink>
  ));

  return (
    <section className="section has-top-divider has-bottom-divider">
      <div className="container">
        <Wrapper>{logos}</Wrapper>
      </div>
    </section>
  );
};

export default Logos;
