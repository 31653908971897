import React from 'react';
import TeamInfoCard from '../elements/TeamInfoCard'

const TeamPage = () => {

    // const TeamHandles = () => {
        return (
          <section>
              <div className="container">
                  <div style={{ borderTop: '1px solid #eee', marginTop: 40, textAlign: 'center' }}>
                      <h3 style={{'margin-top': '70px', 'margin-bottom': '6px'}}>Core Team</h3>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                        <TeamInfoCard 
                            name='Sasha'
                            title='Admin'
                            telegram='@PoodlH00dler'
                            twitter='@PoodleToken'
                            description='20+yrs experience of delivering high end tech projects from startup to corporate. Sales/marketing expert.'
                        />
                        <TeamInfoCard 
                            name='An Oversized Moose With Socks'
                            title='Lead Developer'
                            telegram='@AnOversizedMooseWithSocks'
                            twitter='@undacappn'
                            description='20yrs+ development experience as a software developer in the web and computer graphics industry.'
                        />
                        <TeamInfoCard 
                            name='Joe'
                            title='Admin & Poodlcast Host'
                            twitter='@poodlcast'
                            twitter2='@josiahway'
                            description='Joe has been a stalwart of POODL and hosts our weekly show poodlcast every week. A great guy, always on point.'
                        />
                        <TeamInfoCard 
                            name='SpaceDog'
                            title='Developer'
                            telegram='@WoofInSpace'
                            twitter='@BarkInSpace'
                            description='Multi lingual, Developer and API master. Gamer/father/husband. Loves tech and astronomy.'
                        />
                      </div>
                      <h3 style={{'margin-top': '70px', 'margin-bottom': '6px'}}>Influencers</h3>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                        <TeamInfoCard 
                            name='Crypto Messiah'
                            title='Community Engagement Manager & Influencer'
                            telegram='@1goonrich'
                            twitter='@1goonrich'
                            description='Crypto legend and prophet of smallcaps.'
                        />
                        <TeamInfoCard 
                            name='Travladd Crypto'
                            title='Influencer'
                            telegram='@OfficialTravlad'
                            description="Crypto enthusiast and Token reviewer"
                        />
                        <TeamInfoCard 
                            name='Elle'
                            title='Moderator & Influencer'
                            telegram='@elledonnasiciliana'
                            description="Elle has been with the team since June 2021, and currently in charge of the Poodlympics. She is a guardian of our brand. She's the pitbull of $Poodl."
                        />
                      </div>
                      <h3 style={{'margin-top': '70px', 'margin-bottom': '6px'}}>Moderators</h3>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                        <TeamInfoCard 
                            name='Karin'
                            title='Mod'
                            telegram='@mammakarin'
                            description="Mamma Karin with the magic eraser that helps keep our (room? Community?) clean. Need a virtual hug? She's there for you!"
                        />
                        <TeamInfoCard 
                            name='Yous'
                            title='Mod'
                            telegram='@mightygoodyous'
                            description='Multilingual and king of Reddit. Handles TG rooms and FUD with style. '
                        />
                        <TeamInfoCard 
                            name='Ale'
                            title='Mod'
                            telegram='@Alexapeton'
                            description='Spanish TG POODL OG who takes care of his amigos with games, rewards and chat.'
                        />
                        <TeamInfoCard 
                            name='Russ'
                            title='Mod'
                            telegram='@RussCrypt'
                            description='Instagram master and POODL LORD, POODL OG and long term HODLer.'
                        />
                        <TeamInfoCard 
                            name='Artur'
                            title='Mod'
                            telegram='@Artur19831'
                            description='Multi lingual. Spanish/Italian/English. Superstar shiller and Twitter king. '
                        />
                        <TeamInfoCard 
                            name='Trent'
                            title='Mod'
                            telegram='@trent209'
                            description='Bringing the next generation to POODL. Crypto savvy and always around to help handle questions.'
                        />
                        <TeamInfoCard 
                            name='Jean Pierre'
                            title='Mod'
                            telegram='@Iamjeanpierre'
                            description='POODL French community head. Long term HODLer and POODL afficionado.'
                        />
                        <TeamInfoCard 
                            name='Alex Grey'
                            title='Mod'
                            telegram='@Alex1Grey'
                            description=' Blockchain enthusiast, promoting projects with huge potential,  always works hard for a project that can change the lives of  millions of people.'
                        />
                      </div>
                  </div>
              </div>
          </section>
        );
      };

export default TeamPage;
