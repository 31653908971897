import { gql } from '@apollo/client';

export default {
  GET_ALL: gql`
    query GetPosts($type: PostType) {
      posts(orderBy: date_DESC, where: { type: $type }) {
        id
        title
        date
        slug
        excerpt
        coverImage {
          id
          url
        }
      }
    }
  `,
  GET: gql`
    query GetPost($slug: String) {
      post(where: { slug: $slug }) {
        id
        title
        slug
        excerpt
        type
        coverImage {
          id
          url
        }
        content {
          html
        }
      }
    }
  `,
};
