import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';
import Logo from './partials/Logo';
// import { LANGUAGES } from '../../i18n/i18n';
// eslint-disable-next-line
import { REDDIT, DISCORD, TELEGRAMS, TWITTER, DEXTOOLS_BSC, BSCSCAN, PANCAKE_SWAP, HOTBIT, BAKERYSWAP, ONE_INCH, SUSHISWAP, LBANK, WHITEBIT, DOGEDEX } from '../token/Config';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: '',
  hideNav: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const News = styled.div`
  background-color: #fcbf49;
  text-align: center;
  color: black;
  padding: 10px;

  a {
    color: rgb(255, 0, 122);
  }
`;

const Header = ({ className, navPosition, hideNav, bottomOuterDivider, bottomDivider, ...props }) => {
  const [isActive, setIsactive] = useState(false);
  // const { i18n, t } = useTranslation();
  const { t } = useTranslation();

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = e => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = e => {
    if (!nav.current) return;
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  };

  const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    style: { display: 'flex' },
  };

  // const languages = (
  //   <Menu>
  //     {LANGUAGES.map(l => (
  //       <Menu.Item key={l.key} onClick={() => i18n.changeLanguage(l.key)}>
  //         {l.label}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const buy = (
    <Menu>
      <Menu.Item>
        <a href={PANCAKE_SWAP} {...linkProps}>
          PancakeSwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={HOTBIT} {...linkProps}>
          HOTBIT
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={LBANK}>
          LBank
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={WHITEBIT}>
          WhiteBit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={ONE_INCH}>
          1Inch
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={BAKERYSWAP}>
          BakerySwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={SUSHISWAP}>
          SushiSwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={DOGEDEX}>
          DogeDex
        </a>
      </Menu.Item>
      <Menu.Item>
        <HashLink to="/#howto">{t('howTo')}</HashLink>
      </Menu.Item>
    </Menu>
  );

  const trackers = (
    <Menu>
      <Menu.Item>
        <a {...linkProps} href={DEXTOOLS_BSC}>
          Chart
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={BSCSCAN}>
          BSC Scan
        </a>
      </Menu.Item>
      <Menu.Item>
        <Link to="/report">{t('report')}</Link>
      </Menu.Item>
    </Menu>
  );

  const docs = (
    <Menu>
      <Menu.Item>
        <HashLink to="/#roadmap">Roadmap</HashLink>
      </Menu.Item>
      <Menu.Item>
        <Link to="/learn">Knowledge Base</Link>
      </Menu.Item>
      <Menu.Item>
        <HashLink to="/#howTo">How to Buy</HashLink>
      </Menu.Item>
      <Menu.Item>
        <Link to="/whitepaper">Whitepaper</Link>
      </Menu.Item>
      <Menu.Item>
        <HashLink to="/#audit">Audit</HashLink>
      </Menu.Item>
    </Menu>
  );

  const community = (
    <Menu>
      <Menu.Item>
        <a href={REDDIT} {...linkProps}>
          <span className="iconify" data-icon="akar-icons:reddit-fill" width="20" />
          <span style={{ marginLeft: 10 }}>Reddit</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={DISCORD} {...linkProps}>
          <span className="iconify" data-icon="bi:discord" width="20" />
          <span style={{ marginLeft: 10 }}>Discord</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={TWITTER} {...linkProps}>
          <span className="iconify" data-icon="akar-icons:twitter-fill" width="20" />
          <span style={{ marginLeft: 10 }}>Twitter</span>
        </a>
      </Menu.Item>
      <Menu.SubMenu
        style={{ display: 'flex' }}
        title={
          <span style={{ display: 'flex', color: 'white' }}>
            <span className="iconify" data-icon="ph:telegram-logo-light" width="20" />
            <span style={{ marginLeft: 10 }}>Telegram</span>
          </span>
        }
      >
        {TELEGRAMS.map(({ key, label }) => (
          <Menu.Item key={key}>
            <a href={`https://t.me/${key}`} {...linkProps}>
              {label}
            </a>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item>
        <HashLink to="/#support">Support POODL</HashLink>
      </Menu.Item>
    </Menu>
  );

  const shop = (
    <Menu>
      <Menu.Item>
        <HashLink to="/nfts">NFTs</HashLink>
      </Menu.Item>
      <Menu.Item>
        <a href="https://shop.poodltoken.com/" target="_blank" rel="noopener noreferrer">
          Swag
        </a>
      </Menu.Item>
    </Menu>
  );

  const more = (
    <Menu>
      <Menu.Item>
        <Link to="/media">{t('Media')}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/meme">Memes</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/face">{t('poodlizeMe')}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/poodlympics">Poodlympics</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/charity">Poodl Charities</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/games">Poodl Games</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/team">Poodl Team</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <header {...props} className={classes}>
      {false && (
        <News>
          <Trans i18nKey="news" components={{ a: <a />, b: <b /> }} />
        </News>
      )}
      <div className="container">
        <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
          <Logo />
          {!hideNav && (
            <>
              <button ref={hamburger} className="header-nav-toggle" onClick={isActive ? closeMenu : openMenu}>
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav ref={nav} className={classNames('header-nav', isActive && 'is-active')}>
                <div className="header-nav-inner">
                  <ul className={classNames('list-reset text-xs', navPosition && `header-nav-${navPosition}`)}>
{/*                     <li>
                      <Link to="/news">{t('newsHeader')}</Link>
                    </li> */}
                    <li>
                      <Dropdown overlay={buy} trigger="click">
                        <a>{t('buy')}</a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown overlay={trackers} trigger="click">
                        <a>Trackers</a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown overlay={docs} trigger="click">
                        <a>Learn</a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown overlay={community} trigger="click">
                        <a>{t('community')}</a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown overlay={shop} trigger="click">
                        <a>{t('shop')}</a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown overlay={more} trigger="click">
                        <a>{t('more')}</a>
                      </Dropdown>
                    </li>
                    {/* <li>
                      <Dropdown overlay={languages} trigger="click" arrow>
                        <a>{(LANGUAGES.find(l => l.key === i18n.language) || LANGUAGES[0]).label}</a>
                      </Dropdown>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
