import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import supply from './../../assets/images/feature-tile-icon-03.svg';
import profit from './../../assets/images/feature-tile-icon-02.svg';
import transactions from './../../assets/images/feature-tile-icon-01.svg';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames('tiles-wrap center-content', pushLeft && 'push-left');

  const sectionHeader = {
    title: 'Tokenomics',
    paragraph: t('tokenomics'),
  };

  return (
    <section {...props} className={outerClasses} style={{ margin: '60px 0px' }}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader id="tokenomics" data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={supply} alt="Features tile icon 03" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t('supply')}</h4>
                  <p className="m-0 text-sm">100,000,000,000,000</p>
                  <p className="m-0 text-sm">18% {t('burn')}</p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={transactions} alt="Features tile icon 01" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">3% {t('tax')}</h4>
                  <p className="m-0 text-sm">1% {t('distributed')}</p>
                  <p className="m-0 text-sm">1% {t('burn')}</p>
                  <p className="m-0 text-sm">1% {t('intoLiq')}</p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={profit} alt="Features tile icon 02" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t('profitFrom')}</h4>
                  <p className="m-0 text-sm">{t('profitDesc')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
