
const SocialHandle = ({socialIconName, socialHandle, socialHandleLink, ...rest}) => {
    return(
        <div style={{display: 'flex', flexDirection: 'row'}} key={socialIconName+socialHandle}>
            <span className="iconify" data-icon={socialIconName} data-inline="false" width="30"></span>
            {socialHandleLink && (
                <div><a href={socialHandleLink}>{socialHandle}</a></div>
            )}
            {!socialHandleLink && (
                <div>{socialHandle}</div>
            )}
        </div>
    )
}

export default SocialHandle;