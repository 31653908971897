import SocialHandle from './SocialHandle'
import {Card, Avatar, Image} from "antd";
const { Meta } = Card;

const TeamInfoCard = ({name, title, twitter, twitter2, telegram, description, ...rest}) => {
    return(
        <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'left', width: '400px', transform: 'scale(0.8)'}}>
            
            <Meta
                // avatar={avatarEle}
                title={name}
            />
            {title &&(
                <div style={{display: 'flex', flexDirection: 'row'}} key={name+title}>
                    <Image src='https://www.poodltoken.com/static/media/vectorLogo.d4d03b29.svg' width="30px" />
                    <div>{title}</div>
                </div>
            )}
            <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px black solid', margin: '10px'}} key={name+title}>
                <div style={{ width: 'fit-content'}}>
                    <span className="iconify" data-icon='ant-design:skin-filled' data-inline="false" width="30"></span>
                </div>
                <div style={{textAlign: 'left', width: 'fit-content'}}>
                    {description}<br/><br/>
                </div>
            </div>
            <div>
                {twitter &&(
                    <SocialHandle socialHandle={twitter} socialIconName={'akar-icons:twitter-fill'} socialHandleLink={'https://www.twitter.com/'+twitter}/>
                )}
                {twitter2 &&(
                    <SocialHandle socialHandle={twitter2} socialIconName={'akar-icons:twitter-fill'} socialHandleLink={'https://www.twitter.com/'+twitter2}/>
                )}
                {telegram &&(
                    <SocialHandle socialHandle={telegram} socialIconName={'ph:telegram-logo-light'}/>
                )}
            </div>
        </Card>
    )
}
export default TeamInfoCard