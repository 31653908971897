import React from 'react';
import classNames from 'classnames';
import { COINGECKO, DISCORD, INSTAGRAM, REDDIT, TELEGRAM, TIKTOK, TWITTER, YOUTUBE } from '../../token/Config';

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames('footer-social', className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href={INSTAGRAM}>
            <span className="iconify" data-icon="akar-icons:instagram" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={REDDIT}>
            <span className="iconify" data-icon="akar-icons:reddit-fill" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={DISCORD}>
            <span className="iconify" data-icon="bi:discord" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={COINGECKO}>
            <span className="iconify" data-icon="emojione-monotone:lizard" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={TWITTER}>
            <span className="iconify" data-icon="akar-icons:twitter-fill" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={TELEGRAM}>
            <span className="iconify" data-icon="ph:telegram-logo-light" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={TIKTOK}>
            <span className="iconify" data-icon="icon-park-outline:tiktok" data-inline="false" width="30"></span>
          </a>
        </li>
        <li>
          <a href={YOUTUBE}>
            <span className="iconify" data-icon="logos:youtube-icon" data-inline="false" width="30"></span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
