import {
  HOLDERS,
  // NFTS,
  UNISWAP,
} from '../components/token/Config';

export default {
  report: 'Report',
  buy: 'Buy',

  more: 'More',
  newsHeader: 'Новости',
  news: 'Официального токена BSC POODL - нет. Мы сейчас на <a href="https://www.poodltoken.com/#donate">Coinsbit</a>!',
  the: 'the',
  yield: `Стандартный, беспроблемный, доходный контракт <br /> с более чем ${HOLDERS} держателей`,
  tokenomics: `$POODL - это проверенный и успешный контракт с \
   более чем ${HOLDERS} держателей. Присоединяйтесь пораньше и наслаждайтесь путешествием!`,
  profitFrom: 'Прибыль от хранения',
  profitDesc: 'Ваш баланс увеличивается с каждой транзакцией',
  fun: 'веселье',
  community: 'сообщество',
  supply: 'Первоначальная поставка',
  burn: 'сгорел',
  tax: 'Налог на транзакции',
  distributed: 'Распространено',
  road: 'Будущее',
  audit: 'Аудит безопасности пройден',
  contractAudit: `Смарт-контракт POODL<br/>прошел аудит
    и был одобрен <a href="https://techrate.org/">TechRate</a>`,
  haveFun: '<span>Повеселимся</span>,<br /> ладно?',
  communityTitle: '100% управляемость сообществом',
  communityText: `$POODL - это токен, который управляется и поддерживается исключительно его сообществом.
  С более чем ${HOLDERS} держателей, токен приветствует хранение и
  совместную работу, чтобы сделать POODL успешным`,
  howTo: 'Как купить POODL',
  downloadMeta:
    'Загрузите <a href="https://metamask.io">MetaMask (metamask.io)</a> или используйте существующий кошелек',
  goToUni: `перейти в <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Нажмите',
  topRight: 'вверху справа',
  add: 'добавить',
  clickSettings: 'нажмите Настройки',
  setSlippage: 'и установите slippage на 5,00%',
  gasFees: 'Введите свою сумму и нажмите «Обмен». Не забудьте иметь достаточно ETH для комиссий!',
  ayuda:
    'Мы - проект, движимый сообществом. Если вам нужна помощь или вы хотите принять участие, присоединяйтесь к нам в',
  contract: 'Договор POODL',
  getYourLevel: 'Получите свой POODL уровень',
  poodlizeMe: 'POODLize меня',
};
