import React from 'react';
import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import NftGallery from './NftGallery';
import SectionHeader from '../partials/SectionHeader';
import { GET_ALL, GET_FEATURED } from '../../../query/Nfts';

import poodl10 from '../../../assets/images/nfts/10-thumbnail.png';
import poodl12 from '../../../assets/images/nfts/12-thumbnail.png';
import poodl20 from '../../../assets/images/nfts/20-thumbnail.png';

const Nfts = () => {
  const { artistSlug } = useParams();
  const { data, loading } = useQuery(GET_ALL);

  if (loading) return <div>Loading...</div>;

  const { nfts } = data;

  if (nfts.length === 0) return <div>No NFTs found</div>;

  return (
    <section id="nfts" style={{ paddingTop: 30 }}>
      <SectionHeader
        data={{
          title: 'POODL NFTs',
          paragraph: (
            <>
              Welcome to the official POODL NFT collection. Below you will find all official NFTs created by artists
              around the world contributing to our beloved POODL community!
            </>
          ),
        }}
        className="center-content"
        style={{ position: 'relative', zIndex: 10 }}
      />
      <NftGallery nfts={data.nfts} showBreadcrumbs={!!artistSlug} />
      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <a href="https://airtable.com/shr4xWRKUbuDjELrm" target="_blank" rel="noopener noreferrer">
          <Button size="large" type="primary">
            Want to be a resident artist at POODL?
          </Button>
        </a>
      </div>
    </section>
  );
};

export const FeaturedNfts = () => {
  const { data, loading, error } = useQuery(GET_FEATURED);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>Failed to load Nfts</div>;
  }

  return (
    <section id="nfts">
      <SectionHeader
        data={{
          title: '3D Augmented Reality NFTs',
          paragraph: (
            <>
              This collection of unique NFTs are in full 3D and are fully compatible with the POODL augmented reality app. Use your NFTs in the real world. These NFTs will also be used in a revolutionary model in gaming where YOU earn from others using your models in a series of games over the coming months and years from POODL while still retaining ownership!<br/><br/>
              Each model is unique and accessorized. Be one of the first to own NFTs that will help usher in NFT 2.0 - usable, pinnable, scalable, movable, placeable and viewable in the real world and soon? Playable and providing a passive income stream for owners.
            </>
          ),
        }}
        className="center-content"
        style={{ position: 'relative', zIndex: 10 }}
      />
      <div class="nft-preview">
        <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/82043422881212392986639246154219618395802836846171245588217258050681279873025" target="_blank"><img src={poodl10}/></a>
        <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/82043422881212392986639246154219618395802836846171245588217258052880303128577" target="_blank"><img src={poodl12}/></a>
        <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/82043422881212392986639246154219618395802836846171245588217258061676396150785" target="_blank"><img src={poodl20}/></a>
      </div>
      <div style={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold' }}>
        <a href="https://opensea.io/collection/poodl-ar-pets">See Collection</a>
      </div>
    </section>
  );
};

export default Nfts;
