import { CONTRACT, ETHERSCAN_API_KEY, BSCSCAN_API_KEY, BURN_ADDRESS } from './components/token/Config';
import Web3 from 'web3';

const ETHERSCAN_BASE = 'https://api.etherscan.io/api';
const BSC_SCAN_BASE = 'https://api.bscscan.com/api';
const CG_BASE = 'https://api.coingecko.com/api/v3';
const TEAM_FINANCE_BASE = 'https://backend.team.finance/api/tokens';

export const getTokenPrice = () => {
  return getTokenData().then(data => {
    return data.market_data.current_price.usd;
  });
};

export const getTokenData = () => {
  return fetch(
    `${CG_BASE}/coins/poodle?market_data=true&community_data=false&developer_data=false&sparkline=false`,
  ).then(r => r.json());
};

const getBalance = ({ base, contract, walletID, apiKey }) => {
  const qs = [
    `module=account`,
    `action=tokenbalance`,
    `contractaddress=${contract}`,
    `address=${walletID}`,
    `apikey=${apiKey}`,
  ];
  const url = `${base}?${qs.join('&')}`;
  return fetch(url).then(r => r.json());
};

export const getBscBalance = ({ walletID, contract }) => {
  return getBalance({ base: BSC_SCAN_BASE, walletID, contract, apiKey: BSCSCAN_API_KEY });
};

export const getErcBalance = ({ walletID, contract }) => {
  return getBalance({ base: ETHERSCAN_BASE, walletID, contract, apiKey: ETHERSCAN_API_KEY });
};

export const getMarketChart = () => {
  return fetch(`${CG_BASE}/coins/poodle/market_chart?vs_currency=usd&days=1&interval=hour`).then(r => r.json());
};

export const getTeamFinanceData = () => {
  return fetch(`${TEAM_FINANCE_BASE}/${CONTRACT}`).then(r => r.json());
};

const abi = [
  {
    name: 'totalBurn',
    type: 'function',
    inputs: [],
    outputs: [
      {
        name: 'totalBurn',
        type: 'uint256',
      },
    ],
  },
  {
    name: 'totalSupply',
    type: 'function',
    inputs: [],
    outputs: [
      {
        name: 'totalSupply',
        type: 'uint256',
      },
    ],
  },
];

const callContractMethod = method => {
  const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
  return new web3.eth.Contract(abi, CONTRACT).methods[method]().call();
};

// export const getTotalBurn = () => callContractMethod('totalBurn');
export const getTotalBurn = () =>
  getBscBalance({
    walletID: BURN_ADDRESS,
    contract: CONTRACT,
  }).then(r => r.result);

export const getTotalSupply = () => callContractMethod('totalSupply');
