import { gql } from '@apollo/client';

const nftAtts = `
  id
  url
  sold
  url2
  sold2
  name
  imgUrl
  videoUrl
`;

export const GET_ALL = gql`
  query GetNfts {
    nfts(orderBy: createdAt_DESC) {
      ${nftAtts}
      artist {
        id
        name
        slug
      }
    }
  }
`;

export const GET_FEATURED = gql`
  query GetNfts {
    nfts(orderBy: createdAt_DESC, where: { featured: true }) {
      ${nftAtts}
      artist {
        id
        name
        slug
      }
    }
  }
`;

export const GET_BY_ID = gql`
  query GetNft($id: ID) {
    nft(where: { id: $id }) {
      ${nftAtts}
      artist {
        id
        name
        slug
        instagram
        content {
          html
        }
      }
    }
  }
`;

export const GET_BY_ARTIST = gql`
  query GetArtistNfts($artistSlug: String) {
    artist(where: { slug: $artistSlug }) {
      id
      slug
      name
      website
      instagram
      content {
        html
      }
      nfts {
        ${nftAtts}
        artist {
          id
          name
          slug
        }
      }
    }
  }
`;
