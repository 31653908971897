import { copy } from '../../utils/utils';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CONTRACT } from '../../components/token/Config';

const Contract = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="container">
        <div style={{ position: 'relative' }}></div>
        <div style={{ borderTop: '1px solid #eee', marginTop: 40, textAlign: 'center' }}>
          <h3>{t('contract')}</h3>
          <p style={{ overflowWrap: 'anywhere' }}>{CONTRACT}</p>
          <Button
            type="primary"
            onClick={() => {
              copy(CONTRACT);
              message.info('Copied!');
            }}
          >
            Copy
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Contract;
