import React from 'react';
import Button from '../elements/Button';
import poodlArOne from '../../assets/images/games/poodlOne.jpg';
import poodlArTwo from '../../assets/images/games/poodlTwo.jpg';
import poodlArThree from '../../assets/images/games/poodlThree.jpg';
import poodlArFour from '../../assets/images/games/poodlFour.jpg';

const GamePage = () => {


    return (
        <div id="charity" class="gaming" style={{ marginTop: 50 }}>
            <div className="container"  style={{ fontSize: '2vw', alignContent: 'center', textAlign: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', width: '100vw' }}>
                <div style={{fontSize: '6vw'}}>
                POODL Augmented Reality
                </div>
                <div>
                    <p style={{ textAlign: 'left', marginTop: 20 }}>
                        <p>The POODL Augmented Reality APP lets you see your 3D NFTs through your phones camera and pin them on any real location you like. It is already available on Google Play and will be also available on Appstore shortly.</p>
                    </p>
                </div>
                <div class="gaming-android-apple">
                    <div>
                        <strong>Android</strong>
                        <a href='https://play.google.com/store/apps/details?id=com.poodl.poodl&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <p style={{ fontSize: '12px'}}>Requires Android Version 10+ and<br/><a href="https://play.google.com/store/apps/details?id=com.google.ar.core">Google Play AR Services</a></p>
                    </div>
                    <div>
                        <strong>Apple</strong>
                        <p>In development</p>
                    </div>
                </div>
                <div class="gaming-gallery">
                    <img src={poodlArOne}/><img src={poodlArTwo}/><img src={poodlArThree}/><img src={poodlArFour}/>
                </div>
                <div>
                    <p style={{ textAlign: 'left', marginTop: 65 }}>
                        <p>
                            With the upcoming updates, buyers of our 3D NFTs will be able to pin their own NFTs. You can already find and buy our 3D NFTs in our <a href="https://opensea.io/collection/poodl-ar-pets">$POODL AR Pets</a> collection on OpenSeas.
                        </p>

                        <p>
                            More features, like animations and interaction with your NFTs will be added through updates.
                        </p>

                        <p>
                            POODL is redefining the NFT world and ushering in NFT 2.0
                        </p>

                        <br/>

                        <p>
                            How to use the APP:
                            <ul>
                                <li>Single tap after starting the app: Show the User Interface</li>
                                <li>Plus Button [+]: Opens a drawer to select the NFT</li>
                                <li>Minus Button [-]: Remove last pinned NFT</li>
                                <li>After selecting a NFT from the drawer, point your camera towards a surface and wait until you see some dots indicating the surface</li>
                                <li>Tap the dots to pin the NFT at the surface</li>
                                <li>Move the pinned NFT: Press a signle finger onto the NFT and move it</li>
                                <li>Rotate the pinned NFT: Press two fingers onto the NFT and twist them</li>
                                <li>Scale the pinned NFT: Press two fingers onto the NFT and pinch them</li>
                            </ul>
                        </p>
                    </p>
                </div>
                
            </div>
        </div>
    );
  };

export default GamePage;