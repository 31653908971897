import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

const Wrapper = styled.div`
  background: linear-gradient(to top, #e73578, #f17741);
  padding: 8px;
  margin: 40px 20px;
`;

const Inner = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height 100%;
  width: 300px;
  margin: auto;
  position: relative;
`;

const Card = styled.div`
  background-image: url(${props => props.img});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #222;
  display: flex;
  height: 400px;
  width: 100%;
  transition: all 0.3s ease-in-out;
`;

const Title = styled.div`
  font-weight: bold;
  flex: 1;
  padding: 10px 5px 0px 5px;
  font-size: 25px;
  text-align: center;
  a {
    color: #222 !important;
  }
`;

const Artist = styled.div`
  padding: 0px 10px 10px 10px;
  font-family: 'Caveat';
  flex: 1;
  text-align: right;
  color: black;
`;

const Video = styled.video`
  background: black;
  height: 400px;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  font-size: 24px;
`;

const Buy = styled.a`
  color: #e73578 !important;
  padding: 0px 10px 10px 10px;
  font-family: 'Caveat';
`;

const Sold = styled.div`
  position: absolute;
  font-weight: bold;
  top: 10px;
  right: 10px;
  padding: 0px 5px;
  color: white;
  background-color: #e73578;
  border-radius: 5px;
  z-index: 10;
`;

export const Nft = ({ id, url, url2, name, imgUrl, videoUrl, artist, sold, sold2 }) => {
  let buy;
  if (!url2) {
    buy = (
      <Buy href={url} target="_blank" rel="noopener noreferrer">
        Buy
      </Buy>
    );
  } else {
    buy = (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <a href={url} target="_blank" rel="noopener noreferrer">
                BakerySwap (BSC) {sold && '- (Sold)'}
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href={url2} target="_blank" rel="noopener noreferrer">
                OpenSea (ERC) {sold2 && '- (Sold)'}
              </a>
            </Menu.Item>
          </Menu>
        }
      >
        <Buy>Buy</Buy>
      </Dropdown>
    );
  }

  return (
    <Wrapper>
      <Inner>
        {sold && <Sold>Sold!</Sold>}
        {videoUrl && (
          <Video
            onMouseOver={e => e.target.setAttribute('controls', true)}
            onMouseOut={e => e.target.setAttribute('controls', false)}
            controlslist="nodownload"
            loop
            playsInline
            poster={imgUrl}
            src={videoUrl}
          />
        )}
        {!videoUrl && (
          <Link to={`/nft/${id}`}>
            <Card img={imgUrl} />
          </Link>
        )}
        <Title>
          <Link to={`/nft/${id}`}>{name}</Link>
        </Title>
        <Footer>
          {buy}
          {artist && (
            <Artist>
              by{' '}
              <Link to={`/artist/${artist.slug}`} style={{ color: 'black' }}>
                <u>{artist.name}</u>
              </Link>
            </Artist>
          )}
        </Footer>
      </Inner>
    </Wrapper>
  );
};

export default Nft;
