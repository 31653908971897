import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterSocial from './partials/FooterSocial';
import { LANGUAGES } from '../../i18n/i18n';
import { Menu, Dropdown } from 'antd';



const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames('site-footer center-content-mobile', topOuterDivider && 'has-top-divider', className);
  const { i18n } = useTranslation();
  const languages = (

    <Menu>
      {LANGUAGES.map(l => (
        <Menu.Item key={l.key} onClick={() => i18n.changeLanguage(l.key)}>
          {l.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
          <div className="footer-top space-between text-xxs">
            <Logo />
            <FooterSocial />
            <Dropdown overlay={languages} trigger="click" arrow>
              <a>{(LANGUAGES.find(l => l.key === i18n.language) || LANGUAGES[0]).label}</a>
            </Dropdown>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
