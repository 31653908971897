import {
  AUDIT,
  LOCKED,
  COINGECKO,
  COINMARKETCAP,
  COINSBIT,
  HOLDERS,
  HOTBIT,
  ONE_INCH,
  LBANK,
  // NFTS,
  UNISWAP,
  // Media,
  POODLCAST,
} from '../components/token/Config';

export default {
  report: 'Report',
  buy: 'Buy',
  more: 'More',
  shop: 'Shop',
  newsHeader: 'News',
  // news: `There is no official BSC POODL token. We're live on <a href="${COINSBIT}">Coinsbit</a>!`,
  the: 'the',
  yield: `Standard, frictionless, yield generating contract <br /> with over ${HOLDERS} holders`,
  tokenomics: `$POODL is a proven, successful, audited smart contract with \
    over ${HOLDERS} holders. Join early and enjoy the ride!`,
  profitFrom: 'Profit from Holding',
  profitDesc: 'Your wallet value grows with every transaction',
  fun: 'Fun',
  community: 'Community',
  supply: 'Original Supply',
  burn: 'Burned',
  intoLiq: 'split between charity, marketing, and liquidity',
  tax: 'Tax on Transactions',
  distributed: 'Distributed',
  road: 'The Road Ahead',
  audit: 'Security Audit Passed',
  lockedLp: '5 Years Locked Liquidity',
  contractAudit: `The POODL smart contract has been<br/>audited
    and approved by <a href="${AUDIT}">Solidity Finance</a>`,
  unicryptLocked: `Our PancakeSwap liquidity has been<br/>
    locked for 5 years at <a href="${LOCKED}">Unicrypt</a>`,
  haveFun: 'Have some <span>FUN</span>,<br /> will ya?',
  communityTitle: '100% Community Driven',
  communityText: `
  $POODL is a token that is driven and supported
  solely by its community. With over ${HOLDERS} holders, the token
  has a very even distribution that incentivizes holding and collaborating to make $POODL successful`,
  howTo: 'How to Buy POODL',
  downloadMeta: 'Download <a href="https://metamask.io">MetaMask (metamask.io)</a> or use an existing wallet',
  goToUni: `Go to <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Click',
  topRight: 'on the top right',
  add: 'Add',
  clickSettings: 'Click Settings',
  setSlippage: 'and set slippage to 5.00%',
  gasFees: 'Enter your amount and Swap away. Remember to have enough ETH for gas fees!',
  ayuda: 'We are a community driven project. If you need help or want to get involved, join us on',
  contract: 'POODL Contract',
  getYourLevel: 'Get Your POODL Level',
  poodlizeMe: 'POODLize Me',
  rm1: `
  <li class="checked">Release of POODL AR app</li>
  <li class="checked">Opening of the POODL Crypto Academy</li>
  <li class="checked">Minting of 1st round of 3D augmented Reality NFTs</li>
  <li class="checked">Collaboration with external artists</li>
  <li class="checked">Funding of generative NFT project</li>
  <li>The foundations laid for the Wagmifier project</li>
  <li class="checked">AMA sessions and continued marketing</li>
  <li class="checked">POODL team expansion</li>
  `,
  rm2: `
  <li >POODL moves into real world gaming</li>
  <li >Geocaching</li>
  <li >Side scroller game developed and released</li>
  <li >NFT minting round 2 </li>
  <li >Funding for network bridges explored</li>
  <li >Foundations of POODLVerse with NFT enabled gaming</li>
  <li >Bridge options narrowed</li>
  <li >Increased marketing and charity donations</li>
  `,
  rm3: `
  <li >Network bridges decided</li>
  <li >Funding for bridges complete</li>
  <li >Additional features and functionality around POODL AR gaming</li>
  <li >The POODLVerse is live</li>
  <li >Charities expanded</li>
  <li >POODL community charity initiative</li>
  <li >First bridge completed and live</li>
  `,
};
