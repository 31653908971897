import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import { useParams } from 'react-router';
import Meta from '../Meta';
import NftGallery from './NftGallery';
import SectionHeader from '../partials/SectionHeader';
import { GET_BY_ARTIST } from '../../../query/Nfts';

const Social = styled.a`
  display: flex;
  margin-left: 15px;
  color: #e73578 !important;
`;

const Nfts = () => {
  const { artistSlug } = useParams();
  const { data, loading } = useQuery(GET_BY_ARTIST, { variables: { artistSlug } });

  if (loading) return <div>Loading...</div>;

  const { artist } = data;

  if (!artist) return <div>Artist not found</div>;

  const { name, slug, content, website, instagram } = artist;

  return (
    <section id="nfts" style={{ paddingTop: 30 }}>
      <SectionHeader
        data={{
          title: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span>{name}</span>
              {instagram && (
                <Social href={instagram}>
                  <span className="iconify" data-icon="akar-icons:instagram" data-inline="false" width="30"></span>
                </Social>
              )}
            </div>
          ),
          paragraph: (
            <>
              {!content && (
                <div>
                  Enjoy this collection by our artist in residence <b>{name}</b>.
                </div>
              )}
              {content && <div dangerouslySetInnerHTML={{ __html: content.html }} />}
              <div>
                You can find more info about the artist at{' '}
                <a href={website} target="_blank" rel="noopener noreferer noreferrer">
                  {website}
                </a>
              </div>
            </>
          ),
        }}
        className="center-content"
        style={{ position: 'relative', zIndex: 10 }}
      />
      <NftGallery nfts={artist.nfts} showBreadcrumbs />
      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <a href="https://airtable.com/shr4xWRKUbuDjELrm" target="_blank" rel="noopener noreferrer">
          <Button size="large" type="primary">
            Want to be a resident artist at POODL?
          </Button>
        </a>
      </div>
      <Meta title={name} image={artist.nfts[0] && artist.nfts[0].imgUrl} />
      <div style={{ display: 'none' }} id="tg">
        {instagram && (
          <pre>
            {`
Check out our <a href="https://www.poodltoken.com/artist/${slug}">NFT artwork</a> by ${name}!
Follow them on Instagram <a href="https://www.instagram.com/${instagram}/">@${instagram}</a>
<a href="https://www.poodltoken.com/artist/${slug}">https://www.poodltoken.com/artist/${slug}</a>
`}
          </pre>
        )}
        <pre>
          {`
Check out our <a href="https://www.poodltoken.com/artist/${slug}">NFT artwork</a> by ${name}!
Follow their work on <a href="${website}">${website}</a>
<a href="https://www.poodltoken.com/artist/${slug}">https://www.poodltoken.com/artist/${slug}</a>
`}
        </pre>
      </div>
    </section>
  );
};

export default Nfts;
