import { useQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Meta from '../Meta';
import { isMobile } from '../../../utils/utils';
import { GET_BY_ID } from '../../../query/Nfts';

const ImageWrap = styled.div`
  background: linear-gradient(to top, #e73578, #f17741);
  border-radius: 0px;
  padding: 10px;
  width: 100%;
`;

const Image = styled.img`
  border-radius: 0px;
  width: 100%;
`;

const Video = styled.video`
  background: black;
  height: 290px;
  width: 100%;
`;

const Title = styled.div`
  color: white;
  font-weight: bold;
  font-size: 45px;
`;

const Social = styled.a`
  display: flex;
  margin-left: 15px;
  color: #e73578 !important;
`;

const NftPage = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_BY_ID, {
    variables: { id },
  });

  if (loading) return <div>Loading...</div>;

  const { nft } = data;

  if (!nft) return <div>Not Found</div>;

  const { name, url, url2, artist, videoUrl, imgUrl, sold, sold2 } = nft;

  const mob = isMobile();

  return (
    <>
      <div className="container" style={{ paddingTop: 40 }}>
        <Row gutter={2}>
          <Col span={mob ? 24 : 12} style={{ padding: '0px 20px' }}>
            <Title>{name}</Title>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: 30, marginRight: 10 }}>by </span>
              <Link to={`/artist/${artist.slug}`} style={{ fontSize: 30, textDecoration: 'underline' }}>
                {artist.name}
              </Link>
              {artist.instagram && (
                <Social href={artist.instagram}>
                  <span className="iconify" data-icon="akar-icons:instagram" data-inline="false" width="30"></span>
                </Social>
              )}
            </div>
            <br />
            <br />
            {artist.content && <div dangerouslySetInnerHTML={{ __html: artist.content.html }} />}
            <p>
              A percentage of every POODL NFT sale is donated to one of our charity partners and the rest goes to the
              artist
            </p>
          </Col>
          <Col span={mob ? 24 : 11} offset={mob ? 0 : 1} style={{ padding: '0px 20px' }}>
            {videoUrl && (
              <ImageWrap>
                <Video
                  onMouseOver={e => e.target.setAttribute('controls', true)}
                  onMouseOut={e => e.target.setAttribute('controls', false)}
                  controlslist="nodownload"
                  loop
                  playsInline
                  poster={imgUrl}
                  src={videoUrl}
                />
              </ImageWrap>
            )}
            {!videoUrl && (
              <a href={url}>
                <ImageWrap>
                  <Image src={imgUrl} />
                </ImageWrap>
              </a>
            )}
            {url && (
              <Button
                size="large"
                block
                href={url}
                disabled={sold}
                style={{ marginTop: 60, backgroundColor: '#F17741' }}
              >
                {sold ? 'SOLD' : 'Buy on BakerySwap (BSC)'}
              </Button>
            )}
            {url2 && (
              <Button
                type="primary"
                size="large"
                block
                href={url2}
                disabled={sold}
                style={{ marginTop: 20, backgroundColor: '#F17741' }}
              >
                {sold2 ? 'SOLD' : 'Buy on OpenSea (ERC)'}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Meta title={name} image={imgUrl} />
    </>
  );
};

export default NftPage;
