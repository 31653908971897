/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import { isMobile, canShare } from '../../../utils/utils';
import Button from '../../elements/Button';

const height = window.innerHeight - (isMobile() ? 119 : 300);
const width = isMobile() ? window.innerWidth - 10 : height;

const Wrapper = styled.div`
  background-color: white;
  padding: 5px;
  margin-top: ${isMobile() ? 0 : '10px'};
`;

const Placeholder = styled.div`
  height: ${props => props.height}px;
  width: 100%;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Input = styled.div`
  border: none;
  border-bottom: 2px #888 dotted;
  color: black;
  font-size: 28px;
  text-align: center;
  margin: 0px 10px 10px 10px;
  width: calc(100% - 20px);
`;

const Inner = styled.div`
  padding-bottom: 5px;
`;

const SETTINGS = {
  gltfModelURL: `${process.env.PUBLIC_URL}/face/models/poodl/poodl.glb`,
  cubeMapURL: 'Bridge2/',
  offsetYZ: [1, 0],
  scale: 2.5,
};
let THREECAMERA = null;
function init_threeScene(spec) {
  const threeStuffs = JeelizThreeHelper.init(spec, null);
  const gltfLoader = new THREE.GLTFLoader();
  gltfLoader.load(SETTINGS.gltfModelURL, function (gltf) {
    const ambient = new THREE.AmbientLight(0xffffff, 0.8);
    threeStuffs.scene.add(ambient);

    // CREAT A SPOTLIGHT
    const dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
    dirLight.position.set(100, 1000, 1000);
    threeStuffs.scene.add(dirLight);

    gltf.scene.frustumCulled = false;
    const bbox = new THREE.Box3().expandByObject(gltf.scene);
    const centerBBox = bbox.getCenter(new THREE.Vector3());
    gltf.scene.position.add(centerBBox.multiplyScalar(-1));
    gltf.scene.position.add(new THREE.Vector3(0.2, SETTINGS.offsetYZ[0], SETTINGS.offsetYZ[1]));
    const sizeX = bbox.getSize(new THREE.Vector3()).x;
    gltf.scene.scale.multiplyScalar(SETTINGS.scale / sizeX);
    threeStuffs.faceObject.add(gltf.scene);
  });

  THREECAMERA = JeelizThreeHelper.create_camera();
}
function main(setStarted) {
  JeelizResizer.size_canvas({
    canvasId: 'jeeFaceFilterCanvas',
    isFullScreen: false,
    callback: () => {
      start(setStarted);
    },
    onResize: function () {
      JeelizThreeHelper.update_camera(THREECAMERA);
    },
  });
}

function start(setStarted) {
  JEEFACEFILTERAPI.init({
    videoSettings: { idealWidth: width, idealHeight: height, maxWidth: width, maxHeight: height - 3 },
    followZRot: true,
    canvasId: 'jeeFaceFilterCanvas',
    NNCPath: `${process.env.PUBLIC_URL}/face/`,
    callbackReady: function (errCode, spec) {
      if (errCode) {
        // console.log('AN ERROR HAPPENS. SORRY BRO :( . ERR =', errCode);
        message.error(`Oops, something went wrong: ${errCode}`);
        return;
      }
      console.log('INFO: JEEFACEFILTERAPI IS READY');
      init_threeScene(spec);
      setStarted(true);
    },
    callbackTrack: function (detectState) {
      JeelizThreeHelper.render(detectState, THREECAMERA);
    },
  });
}

const Face = () => {
  const [started, setStarted] = useState(false);

  useEffect(() => {
    main(setStarted);

    return () => {
      setStarted(false);
      JEEFACEFILTERAPI.destroy();
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center ' }}>
      <Wrapper style={{ width: height + 10 }}>
        <Inner id="boundary">
          <canvas
            id="jeeFaceFilterCanvas"
            style={{
              position: started ? 'relative' : 'absolute',
              transform: 'scaleX(-1)',
              height: height - 9,
              width,
            }}
          ></canvas>
          {!started && <Placeholder height={height}>Loading...</Placeholder>}
          <Input className="marker" contentEditable dangerouslySetInnerHTML={{ __html: '$POODL in ...' }} />
        </Inner>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Link to="/">
              <Button style={{ width: '100%' }}>Close</Button>
            </Link>
          </div>
          {started && (
            <Button
              style={{ marginLeft: 10, flex: 1 }}
              onClick={() => {
                html2canvas(document.querySelector('#boundary')).then(canvas => {
                  canvas.toBlob(blob => {
                    if (canShare()) {
                      const file = new File([blob], 'poodl.png', { type: 'image/png' });

                      navigator
                        .share({
                          text: 'Check out PoodlToken!',
                          files: [file],
                          title: 'PoodlToken',
                          url: 'https://www.poodltoken.com',
                        })
                        .then(() => console.log('Share was successful.'))
                        .catch(error => console.log('Sharing failed', error));
                    } else {
                      saveAs(blob, 'poodl.png');
                    }
                  });
                });
              }}
            >
              {canShare ? 'Share' : 'Download'}
            </Button>
          )}
        </div>
      </Wrapper>
      <canvas id="fake" height="0" width="0"></canvas>
    </div>
  );
};

export default Face;
