import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import logo from '../../../assets/images/logo-lg.png';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <div className="m-0">
        <Link to="/">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={logo} alt="POODL" style={{ marginRight: 10, marginLeft: 0, height: '32px', width: '32px' }} />
            <b>Poodl</b>Token&#8482;
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Logo;
