import { COINSBIT, HOLDERS, UNISWAP } from '../components/token/Config';

export default {
  more: 'Más',
  report: 'Reporte',
  buy: 'Comprar',
  newsHeader: 'Novedades',
  news: `No existe una versión oficial de POODL en BSC. POODL esta disponible en <a href="${COINSBIT}">Coinsbit</a>!`,
  the: 'el',
  yield: `Contrato inteligente con generación automática de rendiminento y <br /> más de ${HOLDERS} holders`,
  tokenomics: `$POODL es un contrato probado, auditado y exitoso con más de ${HOLDERS}\
    holders. Únete y disfruta del viaje con nosotros!`,
  profitFrom: 'Beneficiate por tener POODL',
  profitDesc: 'El valor de tu wallet aumenta con cada transacción',
  fun: 'Diversión',
  community: 'Comunidad',
  supply: 'Suministro Inicial',
  burn: 'Quemado',
  tax: 'Impuesto sobre Transacciones',
  distributed: 'Distribuido',
  road: 'El Camino por Delante',
  audit: 'Auditoría Aprobada',
  contractAudit: `El contrato inteligente de POODL <br/> ha sido auditado y aprobado por
  <a href="https://techrate.org/">TechRate</a>`,
  haveFun: '<span>Diviertete</span> un poco, quieres?',
  communityTitle: '100% de la Comunidad',
  communityText: `
  $POODL es un token administrado exclusivamente por su comunidad. Con más de ${HOLDERS} holders, el token
  tiene una distribución que incentiva el holding y la colaboración para que $POODL sea exitoso`,
  howTo: 'Como comprar POODL',
  downloadMeta: 'Descarga <a href="https://metamask.io">MetaMask (metamask.io)</a> o usa tu wallet existente',
  goToUni: `Ve a <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Haz click en',
  topRight: 'arriba a la derecha',
  add: 'Agrega',
  clickSettings: 'Haz click en Settings',
  setSlippage: 'y setea slippage a 5.00%',
  gasFees: 'Ingresa la cantidad deseada y haz click en Swap. Recuerda tener suficiente ETH para los gas fees!',
  ayuda: 'Somos un proyecto de la comunidad. Si necesitas ayuda o quieres involucrarte, unete en ',
  contract: 'Contrato de POODL',
  getYourLevel: '¿Cual es tu nivel de POODL?',
  poodlizeMe: 'Conviertete en POODL',
};
