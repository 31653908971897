import { COINSBIT, HOLDERS, UNISWAP, WHITEBIT } from '../components/token/Config';

export default {
  more: '更多的',
  buy: '买',
  news: `
  <b>新闻:</b>我们已在<a href="${COINSBIT}">Coinsbit</a>上线!
  `,
  the: 'the',
  yield: '标准的，无摩擦，产生收益的合<br /> 拥有6000多个持有者',
  whiteBit: `
      我们已在<a href="${WHITEBIT}">
        WhiteBIT上线
      </a>, 立即获得你的POODL!
    `,
  tokenomics: `$POODL 是一种经过验证的，成功的，经过审计的智能合约，拥有\
    超过 ${HOLDERS} 个持有者。 尽早加入并享受旅程！`,
  profitFrom: '从持有中获利',
  profitDesc: '您的钱包随每笔交易而增长',
  fun: '有趣',
  community: '社区',
  supply: '原始供应',
  burn: '燃烧',
  tax: '交易税',
  distributed: '分布式',
  road: '前方之路',
  audit: '通过安全审核',
  contractAudit: 'POODL智能合约已通过<a href="https://techrate.org/">TechRate</a> 的审核并获得批准',
  haveFun: '获得一些<span>乐趣吧</span>,<br /> 你想吗',
  communityTitle: '100%由社区推动',
  communityText: `
  $POODL 是仅由其社区驱动和
  支持的令牌。 拥有超过${HOLDERS} 个持有者，代币
  的分配非常均匀，激励持有者和协作以使$ POODL成功`,
  howTo: '如何购买POODL',
  downloadMeta: '下载 <a href="https://metamask.io">MetaMask (metamask.io)</a> 或使用现有的钱包',
  goToUni: `前往 <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: '点击',
  topRight: '右上方',
  add: '添加',
  clickSettings: '点击设置',
  setSlippage: '并将滑移率设置为5.00%',
  gasFees: '输入金额然后交换。记住要有足够的以太坊支付燃料费！',
  ayuda: '我们是一个社区驱动的项目。如果您需要帮助或想参与其中，请加入我们的',
  contract: '合约',
  getYourLevel: '获取您的POODL等级',
  poodlizeMe: 'POODLize Me',
};
