import React from 'react';
// import Countdown from 'react-countdown';
import styled, { keyframes } from 'styled-components';
import poodlImg from '../../assets/images/meme/cyborg.png';
import { randomBetween, isMobile } from '../../utils/utils';
import Wall from '../../components/token/Wall';
import { Link } from 'react-router-dom';

const Alert = styled.div`
  background-color: #e73578;
  padding: 5px 15px;
  color: white;
  text-align: center;

  a {
    text-decoration: underline;
  }
`;

const flicker1 = keyframes`
0% {
  opacity: 0.4;
}
5% {
  opacity: 0.5;
}
10% {
  opacity: 0.6;
}
15% {
  opacity: 0.85;
}
25% {
  opacity: 0.5;
}
30% {
  opacity: 1;
}
35% {
  opacity: 0.1;
}
40% {
  opacity: 0.25;
}
45% {
  opacity: 0.5;
}
60% {
  opacity: 1;
}
70% {
  opacity: 0.85;
}
80% {
  opacity: 0.4;
}
90% {
  opacity: 0.5;
}
100% {
  opacity: 1;
}
`;

const flicker2 = keyframes`
0% {
  opacity: 0.19;
}
5% {
  opacity: 0.2;
}
10% {
  opacity: 0.25;
}
15% {
  opacity: 0.35;
}
25% {
  opacity: 0.2;
}
30% {
  opacity: 0.4;
}
35% {
  opacity: 0.1;
}
40% {
  opacity: 0.25;
}
45% {
  opacity: 0.2;
}
60% {
  opacity: 0.4;
}
70% {
  opacity: 0.35;
}
80% {
  opacity: 0.4;
}
90% {
  opacity: 0.2;
}
100% {
  opacity: 0.4;
}
`;

const flicker3 = keyframes`
0% {
  opacity: 0.15;
}
5% {
  opacity: 0.2;
}
10% {
  opacity: 0.12;
}
15% {
  opacity: 0.2;
}
25% {
  opacity: 0.15;
}
30% {
  opacity: 0.4;
}
35% {
  opacity: 0.05;
}
40% {
  opacity: 0.12;
}
45% {
  opacity: 0.15;
}
60% {
  opacity: 0.3;
}
70% {
  opacity: 0.2;
}
80% {
  opacity: 0.3;
}
90% {
  opacity: 0.18;
}
100% {
  opacity: 0.3;
}
`;

const flicker4 = keyframes`
0% {
  opacity: 0.01;
}
5% {
  opacity: 0.05;
}
10% {
  opacity: 0.03;
}
15% {
  opacity: 0.1;
}
25% {
  opacity: 0.07;
}
30% {
  opacity: 0.1;
}
35% {
  opacity: 0.05;
}
40% {
  opacity: 0.06;
}
45% {
  opacity: 0.1;
}
60% {
  opacity: 0;
}
70% {
  opacity: 0.1;
}
80% {
  opacity: 0;
}
90% {
  opacity: 0;
}
100% {
  opacity: 0.1;
}
`;

const flicker5 = keyframes`
0% {
  opacity: 0.1;
}
5% {
  opacity: 0.8;
}
10% {
  opacity: 0.5;
}
15% {
  opacity: 0.3;
}
25% {
  opacity: 0.3;
}
30% {
  opacity: 0.4;
}
35% {
  opacity: 0.2;
}
40% {
  opacity: 0.2;
}
45% {
  opacity: 0.05;
}
60% {
  opacity: 0.1;
}
70% {
  opacity: 0.01;
}
80% {
  opacity: 0;
}
90% {
  opacity: 0.2;
}
100% {
  opacity: 0.1;
}
`;

const Wrapper = styled.div`
  height: 100vh;
`;

const Banner = styled.div`
  font-size: ${() => (isMobile() ? 80 : 180)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: ${() => (isMobile() ? 'column' : 'row')};
  font-family: Monoton;
  // height: 100vh;
`;

const Version = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  height: ${() => (isMobile() ? '300px' : '500px')};
  width: ${() => (isMobile() ? '300px' : '500px')};
  margin: auto;
  opacity: 1;
  color: #f65c32;
  animation: ${props => props.anim} 2s linear reverse infinite;
`;

// const CountdownWrapper = styled.div`
//   position: fixed;
//   color: white;
//   font-weight: bold;
//   bottom: 20px;
//   font-size: 40px;
//   margin: auto;
//   width: 100%;
//   text-align: center;
// `;

const animations = [flicker1, flicker2, flicker3, flicker4, flicker5];

const Letters = styled.div``;

const Letter = styled.span`
  opacity: 1;
  filter: blur(1px);
  color: #f65c32;
  animation: ${props => props.anim} 2s linear reverse infinite;
`;

const Poodl = () => {
  return (
    <>
      <Wrapper>
        <Wall />
        <Alert>
          <Link to="/post/poodl-2-announcement">Read the Announcement</Link>
        </Alert>
        <Banner>
          <Letters>
            <Letter anim={animations[randomBetween(-1, -1)]}>P</Letter>
            <Letter anim={animations[randomBetween(-1, -1)]}>O</Letter>
            <Letter anim={animations[randomBetween(-1, -1)]}>O</Letter>
            <Letter anim={animations[randomBetween(-1, -1)]}>D</Letter>
            <Letter anim={animations[randomBetween(-1, -1)]}>L</Letter>
          </Letters>
          <Version>
            <Letter style={{ color: '#E73578' }} anim={animations[randomBetween(-1, -1)]}>
              2
            </Letter>
            <Letter style={{ color: '#E73578' }} anim={animations[randomBetween(-1, -1)]}>
              .
            </Letter>
            <Letter style={{ color: '#E73578' }} anim={animations[randomBetween(-1, -1)]}>
              0
            </Letter>
          </Version>
        </Banner>
        <Logo src={poodlImg} />
      </Wrapper>
      {/* <CountdownWrapper>
        <span style={{ color: '#888' }}>ANNOUNCEMENT IN</span>{' '}
        <Countdown date={new Date('April 13 2021 23:00:00 GMT')} />
      </CountdownWrapper> */}
    </>
  );
};

export default Poodl;
