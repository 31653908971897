import React from 'react';
// import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
// import Token from '../token/Token';
// import Ticker from '../token/Ticker';
import spinningCoin from '../../assets/images/metalCoinSpin.gif';
// import spinningCoin from '../../assets/images/coin_spin_dithered2.gif';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { TELEGRAMS, PANCAKE_SWAP, HOTBIT, TWITTER, LBANK, WHITEBIT, ONE_INCH, SUSHISWAP, DOGEDEX, BAKERYSWAP, NIKE_NFT, TIKTOK, YOUTUBE } from '../../components/token/Config';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';
import { Timeline } from 'react-twitter-widgets'
import { Helmet } from 'react-helmet';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

// const Alert = styled.div`
//   background-color: #e73578;
//   padding: 5px 25px;
//   color: white;
//   border-radius: 5px;
//   margin-top: 20px;

//   a {
//     text-decoration: underline;
//     word-break: break-all;
//   }
// `;

// const Info = styled.div`
//   background-color: rgb(172, 173, 255);
//   border: 1px solid rgba(255, 255, 255, 0.05);
//   padding: 5px 15px;
//   color: black;
//   border-radius: 5px;
//   margin-top: 20px;

//   a {
//     text-decoration: underline;
//     color: black;
//   }
// `;

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const switchBuyMethod = function(event) {
    const buttonTarget = event.target.dataset.target;

    if (buttonTarget === 'guardarian') {
      document.querySelector('#swapzone').classList.remove('active');
      document.querySelector('#guardarian').classList.add('active');
      document.querySelector('button[data-target="swapzone"]').classList.remove('active');
      document.querySelector('button[data-target="guardarian"]').classList.add('active');
    }

    if (buttonTarget === 'swapzone') {
      document.querySelector('#guardarian').classList.remove('active');
      document.querySelector('#swapzone').classList.add('active');
      document.querySelector('button[data-target="guardarian"]').classList.remove('active');
      document.querySelector('button[data-target="swapzone"]').classList.add('active');
    }
  };

  const telegrams = (
    <Menu>
      {TELEGRAMS.map(({ key, label }) => (
        <Menu.Item key={key}>
          <a href={`https://t.me/${key}`}>{label}</a>
        </Menu.Item>
      ))}
    </Menu>
  );

  const buy = (
    <Menu>
      <Menu.Item>
        <a href={PANCAKE_SWAP} {...linkProps}>
          PancakeSwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={HOTBIT} {...linkProps}>
          HOTBIT
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={LBANK}>
          LBank
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={WHITEBIT}>
          WhiteBit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={ONE_INCH}>
          1Inch
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={BAKERYSWAP}>
          BakerySwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={SUSHISWAP}>
          SushiSwap
        </a>
      </Menu.Item>
      <Menu.Item>
        <a {...linkProps} href={DOGEDEX}>
          DogeDex
        </a>
      </Menu.Item>
      <Menu.Item>
        <HashLink to="/#howto">{t('howTo')}</HashLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm" style={{ position: 'relative', zIndex: 10 }}>
        {/* <Alert>
          We are raising funds to list on LBank - <HashLink to="/#donate">Read more...</HashLink>
        </Alert> */}

        <div className='buyPoodl'>
          <h3>{t('Swap / Buy $POODL now')}</h3>
          <p style={{ overflowWrap: 'anywhere' }}>{t('You can now buy POODL with credit/debit card directly OR swap virtually any crypto!')}</p>
          
          <div class="method-swap">
            <button class="button active" data-target="swapzone" onClick={switchBuyMethod}>Swap with Crypto</button>
            <button class="button" data-target="guardarian" onClick={switchBuyMethod}>Buy with FIAT</button>
          </div>

          <div class="buyPoodlSwap">

            <p style={{ overflowWrap: 'anywhere' }}>{t('Click "buy with FIAT" above to buy with FIAT currency such as US Dollars, Euros or Pounds etc. Or click "swap with crypto" to swap over 400 crypto currencies such as Bitcoin, Ethereum, BNB etc for POODL!')}</p>

            <div id="swapzone" class="active">
              <div id="swapzonePartnersWidget" data-hidelogo="true" data-hideheader="true" data-from="eth" data-to="poodl" data-size="full" data-refid="faW1icfjno" ></div>
            </div>
            
            <div id="guardarian">
              <iframe src="https://guardarian.com/calculator/v1?partner_api_token=28f2dec7-7e5b-41ff-96d2-0c123d19fd20&default_fiat_amount=150&default_fiat_currency=USD&theme=xem&default_crypto_currency=poodl"></iframe>
            </div>
          </div>
        </div>

        <div className={innerClasses} style={{ paddingTop: 40 }}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              HOODL {t('the')} <span className="text-color-primary">$POODL</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                <Trans i18nKey="yield" components={{ br: <br /> }} />
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                
              </div>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            <div style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}>
              <Timeline
                dataSource={{
                  sourceType: 'profile',
                  screenName: 'Poodletoken',
                }}
                options={{
                  height: '400',
                }}
              />
            </div>
            {/* <Token enableGame="true" /> */}
            <div style={{margin: '20px', display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}>
              {/* <Token/> */}
              <img src={spinningCoin} width='400px'/>
            </div>
            
            <div style={{width:'150px', display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}> 
                <div>
                <a href="https://swapzone.io/exchange/eth/poodl?refId=faW1icfjno" target="_blank">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        margin: '5px',
                        width: 150, height: 30,
                        padding: 0,
                        position: 'relative',
                      }}
                    >
                      {t('buy')} POODL
                    </Button>
                  </a>
                  <Dropdown overlay={telegrams}>
                    <Button tag="a" color="primary" wideMobile style={{ textAlign: 'center ', width: 150, height: 30, padding: '0px', margin: '5px' }}>
                      <div style={{ display: 'flex'}}>
                        <span
                          className="iconify"
                          style={{ margin: 'auto'}}
                          data-icon="logos:telegram"
                          data-inline="false"
                        ></span>
                        Telegram
                      </div>
                    </Button>
                  </Dropdown>
                  {/* <Ticker /> */}
                  <a href={TWITTER}>
                    <Button tag="a" color="primary" wideMobile style={{ textAlign: 'center', width: 150, height: 30, padding: '0px', margin: '5px' }}>
                      <div style={{ display: 'flex' }}>
                        <span
                          className="iconify"
                          style={{ margin: '5px' }}
                          data-icon="akar-icons:twitter-fill"
                          data-inline="false"
                        ></span>{' '}
                        Twitter
                      </div>
                    </Button>
                  </a>
                  
                  <a href={TIKTOK}>
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        width: 150,
                        position: 'relative', height: 30, padding: '0px',
                        margin: '5px',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <span
                            className="iconify"
                            style={{ 
                              margin: '5px',
                              position: 'relative', 
                            }}
                            data-icon="icon-park-outline:tiktok"
                            data-inline="false"
                          ></span>{' '}
                        
                        TikTok
                      </div>
                    </Button>
                  </a>
                  
                  <a href={YOUTUBE}>
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        width: 150,
                        position: 'relative', height: 30, padding: '0px',
                        margin: '5px',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <span
                            className="iconify"
                            style={{ 
                              margin: '5px',
                              position: 'relative', 
                            }}
                            data-icon="feather:youtube"
                            data-inline="false"
                          ></span>{' '}
                        
                        YouTube
                      </div>
                    </Button>
                  </a>

                  <Link to="/meme">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        margin: '5px',
                        width: 150, height: 30,
                        padding: 0,
                        position: 'relative',
                      }}
                    >
                      POODL Memes
                    </Button>
                  </Link>
                  
                  <Link to="/face">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        margin: '5px', height: 30,
                        padding: 0,
                        width: 150,
                        position: 'relative',
                      }}
                    >
                      {t('poodlizeMe')}
                    </Button>
                  </Link>

                  <Link to="/poodlympics">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        margin: '5px', height: 30,
                        padding: 0,
                        width: 150,
                        position: 'relative',
                      }}
                    >
                      Poodlympics
                    </Button>
                  </Link>
                  
                  <a href="/team">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white', 
                        margin: '5px',
                        width: 150, height: 30,
                        padding: 0,
                        position: 'relative',
                      }}
                    >
                      The Poodl Team
                    </Button>
                  </a>

                  <Link to="/charity">
                    <Button
                      style={{
                        backgroundColor: '#E73578',
                        color: 'white',
                        margin: '5px', height: 30,
                        padding: 0,
                        width: 150,
                        position: 'relative',
                      }}
                    >
                      Poodl Charities
                    </Button>
                  </Link>
                  
                </div>
                <div>
                  
                </div>
              </div>
          
          </div>
        </div>
      </div>
      <canvas
        id="circles"
        style={{
          position: 'absolute',
          left: -100,
          top: 0,
          height: window.innerHeight + 200,
          width: window.innerWidth + 200,
          zIndex: 0,
        }}
      />

      <Helmet>
        <script src="https://swapzone.io/script/partners-widget.js"></script>
      </Helmet>

    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
