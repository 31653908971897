import { COINSBIT, HOLDERS, NFTS, UNISWAP } from '../components/token/Config';

export default {
  more: 'Suite',
  buy: 'Acheter',
  news: `
  <b>Actualités:</b> Nous sommes disponibles sur <a href="${COINSBIT}">Coinsbit</a>,
  POODL <a href="${NFTS}">NFTs</a>!
  `,
  the: 'le',
  shop: 'Boutique',
  yield: `Contrat standard, sans accros et générant des rendements <br/> avec plus de ${HOLDERS} holders`,
  tokenomics: `$POODL est un contrat intelligent réussi, audité et validé ayant \
    plus de ${HOLDERS} holders. Rejoignez-nous dès maintenant et profitez !`,
  profitFrom: 'Générez des bénéfices en gardant vos pièces',
  profitDesc: 'La valeur de votre portfolio augmente à chaque transaction',
  fun: 'Fun',
  community: 'Communauté',
  supply: 'Réserve Originale',
  burn: 'Brûlées',
  tax: 'de Taxes sur chaque Transaction',
  distributed: 'Redistribué',
  road: 'Les Nouveautés à Venir',
  intoLiq: 'split between charity, marketing, and liquidity',
  audit: 'Audit de sécurité réussi',
  contractAudit: `Le contrat intelligent POODL a été<br/>audité
    et approuvé par <a href="https://techrate.org/">TechRate</a>`,
  haveFun: "Allez, viens <span>t'amuser!</span>",
  communityTitle: '100% Dirigé par la communauté',
  communityText: `
  $POODL est une pièce dirigée et supportée
  exclusivement par sa communauté. Avec plus de ${HOLDERS} holders, la pièce
  a une distribution très équilibrée qui encourage la collaboration et la garde
  ses pièces afin de faire réussir le $POODL successful`,
  howTo: 'Comment acheter du POODL',
  downloadMeta:
    'Téléchargez <a href="https://metamask.io">MetaMask (metamask.io)</a> ou utilisez un portefeuille existant',
  goToUni: `Allez sur <a href="${UNISWAP}"}>Uniswap (app.uniswap.org)</a>`,
  click: 'Cliquez sur',
  topRight: 'en haut à droite',
  add: 'Ajoutez le',
  clickSettings: 'Cliquer sur les paramètres',
  setSlippage: 'et définissez le taux de glissement à 5.00%',
  gasFees: `Entrez le montant et Echangez. Rappelez vous d'avoir assez d'ETH pour
  satisfaire les frais de gas!`,
  ayuda: `Nous sommes un projet dirigé par sa communauté. Si vous avez
    besoin d'aide ou que vous voulez nous rejoindre, contactez-nous sur`,
  contract: 'Contrat',
  getYourLevel: 'Obtenez Votre Niveau POODL',
  poodlizeMe: 'POODLizez Moi',
};
