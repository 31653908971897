import styled from 'styled-components';

const Paper = styled.div`
  background-color: rgba(254, 249, 235, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 1200px;
  margin: auto;
  padding-bottom: 40px;
`;

export default Paper;
