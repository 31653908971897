import styled from 'styled-components';
import { Row, Col, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Nft from './Nft';

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 80px;
`;

const NftGallery = ({ nfts, showBreadcrumbs }) => {
  const images = nfts.map(i => (
    <Col
      key={i.id}
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      lg={{ span: 8 }}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Nft {...i} />
    </Col>
  ));

  return (
    <Images className="container" style={{ margin: 'auto' }}>
      {showBreadcrumbs && nfts.length > 0 && (
        <Breadcrumb style={{ marginLeft: 40 }}>
          <Breadcrumb.Item>
            <Link to={`/nfts`}>All NFTS</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/artist/${nfts[0].artist.slug}`}>{nfts[0].artist.name}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Row type="flex" style={{ padding: '0px 20px', width: '100%' }}>
        {images}
      </Row>
    </Images>
  );
};

export default NftGallery;
