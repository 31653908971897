import React from 'react';
import ActivationWrapper from './WalletActivate';

const WalletActivationPage = () => {


  return (
    <div style={{display:"flex", flex: 1, justifyContent: "center", flexDirection:"column"}}>
        <div style={{display: "flex", justifyContent: "center", fontSize: "32px", color: "#FFFFFF"}}>
            Generate Activation Code
        </div>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto", flexDirection: "column", borderRadius: 15, width: '400px', justifyContent: "center", padding: '30px', backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
            <div style={{marginBottom: "20px"}} >Steps to activate our app and link it to your wallet:</div>
            <div style={{marginBottom: "20px"}} >
                <ol>
                    <li>Connect Wallet</li>
                    <li>Copy Activation Code</li>
                    <li>Enter code in app</li>
                    <li>Activate</li>
                </ol>
            </div>
            <ActivationWrapper/>
        </div>
    </div>
  );
};

export default WalletActivationPage;


