import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import styled from 'styled-components';
import Colors from '../../Colors';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const Card = styled.div`
  background: linear-gradient(to top, ${Colors.primary[0]}, ${Colors.primary[2]});
  border-radius: 20px;
  padding: 20px 10px 20px 0px;
  margin: 0px;
  color: white;
  height: 620px;

  ul {
    list-style: none;
  }

  ul li.checked:before {
    content: '✓';
    color: green;
    margin-right: 5px;
    font-weight: 800;
    font-size: 20px;
  }

  a {
    text-decoration: underline !important;
  }
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const Wrapper = styled.div``;

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Roadmap = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames('tiles-wrap center-content', pushLeft && 'push-left');

  const sectionHeader = {
    title: t('road'),
  };

  return (
    <section {...props} className={outerClasses} id="roadmap">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader id="tokenomics" data={sectionHeader} className="center-content" />
          <Wrapper className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <Card>
                <Header className="mb-16">Q4 - 2021</Header>
                <ul style={{ textAlign: 'left' }}>
                  <Trans i18nKey="rm1" components={{ li: <li />, a: <a /> }} />
                </ul>
              </Card>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <Card>
                <Header className="mb-16">Q1 - 2022</Header>
                <ul style={{ textAlign: 'left' }}>
                  <Trans i18nKey="rm2" components={{ li: <li />, a: <a /> }} />
                </ul>
              </Card>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <Card>
                <Header className="mb-16">Q2 - 2022</Header>
                <ul style={{ textAlign: 'left' }}>
                  <Trans i18nKey="rm3" components={{ li: <li />, a: <a /> }} />
                </ul>
              </Card>
            </div>
          </Wrapper>
        </div>
      </div>
    </section>
  );
};

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
