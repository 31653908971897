import React from 'react';
import styled from 'styled-components';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { isMobile } from '../../utils/utils';
import { SPOTIFY_PLAYLIST } from '../../components/token/Config';

const Thumb = styled.img`
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  height: ${() => (isMobile() ? window.innerWidth / 2 - 15 : '200')}px;
  margin: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #555;
    transform: translateY(-5px);
  }

  .slick-dots button {
    background-color: white;
  }
`;

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  justify-content: center;
`;

const N_IMAGES = 22;

const Media = () => {
  const images = [];
  for (let i = 1; i <= N_IMAGES; i++) {
    const src = require(`../../assets/images/media/${i}.jpg`).default;
    images.push(<Thumb key={i} src={src} />);
  }

  const vectorLogo = require(`../../assets/images/media/vectorLogo.svg`).default;
  images.push(<Thumb key={N_IMAGES+1} src={vectorLogo} />);

  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
          <iframe
            src={SPOTIFY_PLAYLIST}
            width="300"
            height="300"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
        <Wrapper>{images}</Wrapper>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default Media;
