import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cache from 'i18next-localstorage-cache';

export const LANGUAGES = [
  {
    key: 'ch',
    label: '中文',
  },
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'es',
    label: 'Español',
  },
  {
    key: 'fr',
    label: 'French',
  },
  {
    key: 'it',
    label: 'Italiano',
  },
  {
    key: 'pl',
    label: 'Polski',
  },
  {
    key: 'ru',
    label: 'Pусский',
  },
];

const resources = {};

LANGUAGES.map(l => {
  resources[l.key] = {
    translation: require(`./${l.key}`).default,
  };
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Cache)
  .init({
    fallbackLng: 'en',
    Cache: {
      enabled: false,
      prefix: 'translation_',
      expirationTime: Infinity,
      Version: {},
    },
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
