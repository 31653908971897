import { useQuery } from '@apollo/client';
import { Breadcrumb, Layout, Spin } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Posts from '../../query/Posts';
import Paper from '../elements/Paper';
import { isMobile } from '../../utils/utils';

const Article = styled.div``;

const Title = styled.div`
  color: #222;
  font-family: Bangers;
  font-size: 38px;
  white-space: pre-wrap;
  padding: 30px 40px 0px 40px;
`;

const Content = styled.div`
  color: #444;
  padding: 20px 40px 40px 40px;
  font-size: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  a {
    color: #333;
  }

  a {
    color: #e73578;
    text-decoration: underline;
  }

  img {
    margin: auto;
  }

  em {
    display: inherit;
    text-align: center;
  }
`;

const Cover = styled.div`
  background-image: url(${props => props.src});
  background-color: #222;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: ${isMobile() ? '200px' : '400px'};
`;

const Post = () => {
  const { slug } = useParams();
  const { data, loading } = useQuery(Posts.GET, {
    variables: { slug },
  });

  if (loading) return <Spin />;

  const {
    post: {
      title,
      content: { html },
      coverImage: { url },
      type,
    },
  } = data;

  const section = {
    News: {
      id: 'news',
      label: 'News',
    },
    KnowledgeBase: {
      id: 'learn',
      label: 'Learn',
    },
    Draft: {
      id: 'drafts',
      label: 'Drafts',
    },
  };

  return (
    <Layout style={{ background: 'transparent' }}>
      <Layout.Content>
        <div style={{ maxWidth: 700, padding: 20, margin: 'auto' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/${section[type].id}`}>{section[type].label}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Paper style={{ maxWidth: 700 }}>
          <Article>
            <Cover src={url} />
            <Title>{title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: html }} />
          </Article>
        </Paper>
      </Layout.Content>
    </Layout>
  );
};

export default Post;
