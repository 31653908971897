import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Sphere from '../elements/Sphere';
import { TELEGRAMS } from '../token/Config';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Community = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses} style={{ position: 'relative' }}>
          <SectionHeader
            data={{
              title: t('communityTitle'),
            }}
            className="center-content"
            style={{ position: 'relative', zIndex: 10 }}
          >
            <div className="container-xs">
              {t('communityText')}
              <h3>Join Your POODL Community</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {TELEGRAMS.map(({ key, label }) => (
                  <Button key={key} href={`https://t.me/${key}`} style={{ flex: '0 0 25%', margin: 5 }} target="_blank">
                    {label}
                  </Button>
                ))}
              </div>
            </div>
          </SectionHeader>
          {/* <SectionHeader
            id="support"
            data={{
              title: 'Support POODL',
            }}
            className="center-content"
            style={{ position: 'relative', zIndex: 10 }}
          >
            <div className="container-xs">
              As a community run token, POODL is only supported by our members. We have a dedicated group of designers,
              engineers, developers, marketers, sales, and more, investing their time and money to run this project. If
              you can donate anything to our marketing efforts it would mean the world to us.
            </div>
            <div>Send donations only to this address in BNB</div>
            <Button
              size="large"
              style={{ marginTop: 20 }}
              onClick={() => {
                copy(MARKETING_WALLET);
                message.info('Copied!');
              }}
            >
              {MARKETING_WALLET}
            </Button>
          </SectionHeader> */}
          <Sphere />
        </div>
      </div>
    </section>
  );
};

Community.propTypes = propTypes;
Community.defaultProps = defaultProps;

export default Community;
