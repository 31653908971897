import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Modal } from 'antd';
import MemeGenerator from './MemeGenerator';
import { isMobile } from '../../utils/utils';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${() => (isMobile() ? 'column' : 'row')};
  margin: 0px ${() => (isMobile() ? '0px' : '200px')}; ;
`;

const Col = styled.div`
  padding: 20px;
`;

const MemePage = () => {
  const [submitModal, setSubmitModal] = useState(false);
  const galleryRef = useRef();

  const cardProps = {
    style: { background: 'transparent', border: 'none' },
    headStyle: { fontWeight: 'bold', fontSize: isMobile() ? 'auto' : 30, border: 'none' },
    bodyStyle: { padding: 0 },
  };

  return (
    <Wrapper>
      <Modal visible={submitModal} onCancel={() => setSubmitModal(false)} bodyStyle={{ padding: 0 }} footer={null}>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrAmJUZXbDYXTTL6?backgroundColor=blue"
          frameBorder="0"
          width="100%"
          height="1200"
        ></iframe>
      </Modal>
      <Col>
        <Card title="Create your POODL meme" {...cardProps}>
          <MemeGenerator />
        </Card>
      </Col>
      <Col style={{ flex: 1 }}>
        <Card
          {...cardProps}
          title="Community Memes"
          extra={
            <Button onClick={() => setSubmitModal(true)} size="" type="primary">
              Submit
            </Button>
          }
        >
          <div style={{ borderRadius: 15, overflow: 'hidden' }}>
            <iframe
              ref={galleryRef}
              className="airtable-embed"
              src="https://airtable.com/embed/shrUdjKfFgLcnez7y?backgroundColor=blue&viewControls=on"
              frameBorder="0"
              width="100%"
              height="1200"
            ></iframe>
          </div>
        </Card>
      </Col>
    </Wrapper>
  );
};

export default MemePage;
