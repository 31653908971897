// import 'aframe';
// import { Scene, Entity } from 'aframe-react'

// const modelSource = require(`https://xlmoose.com/nft/cg/chubbyPoodl.js`).default;

const VRTestPage = () => {
    return (
        <div style={{width: "300", height: "400"}}>
            {/* <Scene>
                <Entity light={{type: 'point'}}/>
                <Entity geometry={{primitive: 'box'}} material={{color: 'red'}} position={{x: 0, y: 0, z: -5}}/>
                <Entity obj-model={{obj: 'https://docs.google.com/uc?export=download&id=1tXA1vlyo486Evav5c9fa18EAELY_w7u3'}} position={{x: 0, y: 0, z: -15}}/>
                <Entity text={{value: 'Welcome, Poodl Hodler!'}}/>
            </Scene> */}
      </div>
    );
};

export default VRTestPage;