import React from 'react';
import styled, { keyframes } from 'styled-components';
import wall from '../../assets/images/media/wall.png';

const Wrapper = styled.div`
  overflow: hidden;
  position: absolute;
  height: 120%;
  top: -20%;
  left: 0px;
  z-index: -1;
`;

const slide = keyframes`
0% {
  transform: rotateZ(10deg) translateX(0);
}
100% {
  transform: rotateZ(10deg) translateX(-1692px);
}
`;

const Sliding = styled.div`
  background: url(${wall});
  background-repeat: repeat;
  height: 100%;
  width: 5076px;
  transform-origin: 0 0;
  animation: ${slide} 120s linear infinite;
`;

const Wall = () => {
  return (
    <Wrapper id="wall">
      <Sliding />
    </Wrapper>
  );
};

export default Wall;
