import React from 'react';
import { useTranslation } from 'react-i18next';
import howTo from '../../assets/images/how-to.png';
import Image from '../elements/Image';
import { isMobile } from '../../utils/utils';

const HowTo = () => {
  const { t } = useTranslation();

  return (
    <section id="howto" style={{ marginTop: 50 }}>
      <div className="container">
        <div style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row' }}>
          <div>
            <Image src={howTo} />
          </div>
        </div>
        <p style={{ textAlign: 'center', marginTop: 20 }}>
          {t('ayuda')} <a href="https://t.me/Poodl">Telegram</a>
        </p>
      </div>
    </section>
  );
};

export default HowTo;

// import React, { useState } from 'react';
// import { Trans, useTranslation } from 'react-i18next';
// import { Button, Modal, message } from 'antd';
// import { PlayCircleOutlined, CopyOutlined, SettingOutlined } from '@ant-design/icons';
// import howTo from '../assets/images/how-to.png';
// import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
// import { isMobile } from '../../utils/utils';
// import uni from '../../assets/images/uni.png';
// import { copy } from '../../utils/utils';
// import { CONTRACT } from '../../components/token/Config';

// const HowTo = () => {
//   const [howToModal, setHowToModal] = useState(false);
//   const { t } = useTranslation();

//   const steps = [
//     {
//       title: (
//         <div>
//           <Trans i18nKey="downloadMeta" components={{ a: <a /> }} />
//         </div>
//       ),
//     },
//     {
//       title: (
//         <div>
//           <Trans i18nKey="goToUni" components={{ a: <a /> }} />
//         </div>
//       ),
//     },
//     {
//       title: (
//         <div>
//           {t('click')}
//           <Button
//             style={{
//               background: 'rgb(253, 234, 241)',
//               fontWeight: '500',
//               color: 'rgb(255, 0, 122)',
//               borderRadius: 10,
//               margin: '0px 5px',
//             }}
//           >
//             Connect to a wallet
//           </Button>{' '}
//           {t('topRight')}
//         </div>
//       ),
//     },
//     {
//       title: (
//         <div>
//           {t('click')}
//           <Button
//             style={{
//               background: 'rgb(255, 0, 122)',
//               fontWeight: '500',
//               color: 'white',
//               borderRadius: 10,
//               margin: '0px 5px',
//             }}
//           >
//             Select a token
//           </Button>
//         </div>
//       ),
//     },
//     {
//       title: (
//         <div>
//           <div>{t('add')} POODL token</div>
//           <div style={{ overflowWrap: 'anywhere' }}>
//             <a
//               onClick={() => {
//                 copy(CONTRACT);
//                 message.info('Copied!');
//               }}
//             >
//               {CONTRACT}{' '}
//             </a>
//             <Button
//               onClick={() => {
//                 copy(CONTRACT);
//                 message.info('Copied!');
//               }}
//               icon={<CopyOutlined />}
//             />
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: (
//         <div>
//           {t('clickSettings')} <SettingOutlined /> {t('setSlippage')}
//         </div>
//       ),
//     },
//     {
//       title: <div>{t('gasFees')}</div>,
//     },
//   ];

//   return (
//     <section>
//       <div className="container">
//         <SectionHeader
//           style={{ position: 'relative', zIndex: 10 }}
//           id="howto"
//           data={{
//             title: t('howTo'),
//           }}
//           className="center-content"
//         />
//         <div style={{ textAlign: 'center', marginBottom: 20 }}>
//           <Modal
//             width={640}
//             title={t('howTo')}
//             visible={howToModal}
//             onCancel={() => setHowToModal(false)}
//             footer={null}
//             bodyStyle={{ padding: 0 }}
//           >
//             <iframe
//               src="https://player.vimeo.com/video/533125718"
//               width="640"
//               height="360"
//               frameBorder="0"
//               allow="autoplay; fullscreen; picture-in-picture"
//               allowFullScreen
//             ></iframe>
//             <p style={{ padding: '3px 10px 10px 10px' }}>
//               <a href="https://vimeo.com/533125718">{t('howTo')}</a> from{' '}
//               <a href="https://vimeo.com/user137240736">Poodl Token</a> on <a href="https://vimeo.com">Vimeo</a>.
//             </p>
//           </Modal>
//           <Button icon={<PlayCircleOutlined />} type="primary" size="large" onClick={() => setHowToModal(true)}>
//             Show Me!
//           </Button>
//         </div>
//         <div style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row' }}>
//           <div style={{ paddingRight: '20px' }}>
//             <ul style={{ listStyle: 'none' }}>
//               {steps.map((s, i) => (
//                 <li key={i}>
//                   <div style={{ fontSize: 20, display: 'flex' }}>
//                     <Button shape="circle" type="primary" style={{ color: 'black', flex: 0 }}>
//                       <b>{i + 1}</b>
//                     </Button>{' '}
//                     <div style={{ flex: 1, minHeight: '40px', marginLeft: '10px' }}>{s.title}</div>
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//           <div>
//             <Image src={uni} />
//           </div>
//         </div>
//         <p style={{ textAlign: 'center', marginTop: 20 }}>
//           {t('ayuda')} <a href="https://t.me/Poodl">Telegram</a>
//         </p>
//       </div>
//     </section>
//   );
// };

// export default HowTo;
