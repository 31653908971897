import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useInterval } from './hooks';
import { Doughnut, Line } from 'react-chartjs-2';
import Colors from '../../Colors';
import { getTokenData, getTotalBurn, getMarketChart, getTotalSupply } from '../../api';
import { formatCoins, formatMoney, formatPercentage, isMobile } from '../../utils/utils';
import styled from 'styled-components';
import { DEXTOOLS_BSC } from './Config';

const mobile = isMobile();

const Wrapper = styled.div`
  background-color: #333;
  border-radius: 20px;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;
const Item = styled.div`
  margin: 10px 0px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`;
const Value = styled.div`
  color: white;
`;

const Outer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: ${() => (mobile ? 'column' : 'row')};
`;

const GraphWrap = styled.div`
  padding: 0px 0px;
`;

const DIVIDER = 1e9;

const Report = () => {
  const [price, setPrice] = useState(null);
  const [up, setUp] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [totalBurn, setTotalBurn] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [marketChart, setMarketChart] = useState(null);

  const load = () => {
    getTokenData().then(d => {
      const newPrice = d.market_data.current_price.usd;
      setPrice(newPrice);
      setUp(newPrice >= price);
      setTokenData(d);
    });
    getMarketChart().then(setMarketChart);
    getTotalSupply().then(setTotalSupply);
    getTotalBurn().then(setTotalBurn);
  };

  useEffect(load, []);
  useInterval(load, 1000 * 30);

  useInterval(() => {
    getMarketChart().then(setMarketChart);
  }, 1000 * 60 * 60);

  if (!tokenData || !marketChart || !totalSupply)
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  const { market_data: m } = tokenData;
  const circulatingSupply = (totalSupply - totalBurn) / DIVIDER;
  const burnedSupply = totalBurn / DIVIDER;
  const burnetPct = (100 * burnedSupply) / (burnedSupply + circulatingSupply);

  return (
    <Outer>
      <Wrapper style={{ zIndex: 1, width: mobile ? '100%' : 400, marginRight: 10 }}>
        <Item>
          <Title>Current Price</Title>
          <Value style={{ color: up ? '#17c671' : '#c4183c' }}>${formatCoins(m.current_price.usd)}</Value>
        </Item>
        <Item>
          <Title>Market Cap</Title>
          <Value>${formatMoney(m.current_price.usd * circulatingSupply)}</Value>
        </Item>
        <Item>
          <Title>24h Trading Volume</Title>
          <Value>${formatMoney(m.total_volume.usd)}</Value>
        </Item>
        <GraphWrap>
          <Line
            height={300}
            width={400}
            legend={null}
            options={{
              title: {
                display: true,
                text: '24h Price',
              },
              scales: {
                xAxes: [
                  {
                    type: 'time',
                  },
                ],
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              tooltips: {
                callbacks: {
                  label: tooltipItem => {
                    return formatCoins(tooltipItem.yLabel);
                  },
                  afterLabel: () => null,
                },
              },
            }}
            data={{
              datasets: [
                {
                  labels: [],
                  data: marketChart.prices.map(p => ({
                    t: new Date(p[0]),
                    y: p[1],
                  })),
                  fill: false,
                  borderColor: 'rgb(75, 192, 192)',
                  tension: 0.1,
                },
              ],
            }}
          />
        </GraphWrap>
        <a href={DEXTOOLS_BSC} target="_blank" rel="noopener noreferrer">
          <Button block style={{ marginTop: 20, marginBottom: 10 }}>
            View Live Chart
          </Button>
        </a>
      </Wrapper>
      <Wrapper
        style={{
          backgroundColor: '#282828',
          zIndex: 0,
          marginTop: 20,
          height: 'calc(100% - 20px)',
          marginLeft: mobile ? 0 : -30,
          paddingLeft: mobile ? 20 : 40,
          width: mobile ? '100%' : 300,
        }}
      >
        <Item>
          <Title>Original Supply</Title>
          <Value>{formatCoins(totalSupply / DIVIDER)}</Value>
        </Item>
        <Item>
          <Title>Circulating Supply ({formatPercentage(100 - burnetPct)}%)</Title>
          <Value style={{ color: Colors.primary[1] }}>{formatCoins(circulatingSupply)}</Value>
        </Item>
        {totalBurn && (
          <Item>
            <Title>Total Burned ({formatPercentage(burnetPct)}%)</Title>
            <Value style={{ color: Colors.primary[0] }}>{formatCoins(burnedSupply)}</Value>
          </Item>
        )}
        <GraphWrap style={{ padding: 20 }}>
          <Doughnut
            height={300}
            width={300}
            legend={null}
            data={{
              labels: ['Circulating', 'Burned'],
              datasets: [
                {
                  data: [circulatingSupply, burnedSupply],
                  backgroundColor: [Colors.primary[1], Colors.primary[0]],
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              tooltips: {
                callbacks: {
                  title: ([tooltipItem], { labels }) => {
                    return labels[tooltipItem.index];
                  },
                  label: (tooltipItem, { datasets }) => {
                    const v = datasets[0].data[tooltipItem.index];
                    return formatPercentage((100 * v) / totalSupply) + '%';
                  },
                  afterLabel: () => null,
                },
              },
            }}
          />
        </GraphWrap>
      </Wrapper>
    </Outer>
  );
};

export default Report;
