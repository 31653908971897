import { Button, Spin } from 'antd';
import React, {useEffect, useState } from 'react';


//const poodlApi = 'https://localhost:5001/api/';
const poodlApi = 'https://poodlapi.xlmoose.com/api/';
const top10Url = poodlApi+'Twitter/poodlympics/top10/';

const fetchTop10 = weekOffset => {
    return fetch(top10Url+weekOffset, { 
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin':'*',
        },
    }).then(r => {
        console.log(r);
        return r.json();
    });
};

// const fetchTop10 = () => {
//     console.log("fetching from: "+ top10Url);
//     fetch(top10Url)
//         .then(response => response.json())
//         .then(data => console.log(data));
//     return new Promise(() => {return {}});
// }

const getTop10 = weekOffset => {
    return fetchTop10(weekOffset).then(r => {
        // Failed, schedule again
        if (r.status === '0') {
        return new Promise(resolve => {
            setTimeout(() => {
                fetchTop10(weekOffset).then(r => resolve(r));
            }, 6000);
        });
        } else {
            return r;
        }
    });
};

const PoodlTop10 = () => {
    const [top10, setTop10] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [weekOffset, setWeekOffset] = useState(0);

    useEffect(() => {
        if (!top10 && !loading) {
          setLoading(true);
          getTop10(weekOffset)
            .then(topList => {
                if (topList.status === '0') {
                    setError(true);
                    return false;
                  } else {
                    setTop10(topList);
                    return true;
                  }
            })
            .then(proceed => {
              setLoading(false);
              if(proceed && !error){
                  console.log("poodlympics top10 loaded");
              }
            })
        }
    });

    const nextWeek = () => {
        setTop10(null);
        setWeekOffset(weekOffset-1);
    }
    const lastWeek = () => {
        setTop10(null)
        setWeekOffset(weekOffset+1);
    }

    let top10List = (
        <div style={{display: "flex", flexDirection : "row"}} >
            <div ></div><div></div>
        </div>
    );

    let dateRange = (
        <div style={{display: "flex", flexDirection : "row"}} >
            <div/><div/>
        </div>
    );

    console.log(top10);
    if(top10 != null){
        top10List = top10.results.map(u => (
                <div style={{display: "flex", flexDirection : "row"}} key={u.name+"_row"}>
                    <div style={{display: "flex", alignItems: "center", width: "34px", padding: "5px", borderWidth: "1px", borderRight: "0px", borderColor: "black", borderStyle: "solid"}} key={u.name+"_pfp"}><img src={u.pfp} hidden={u.pfp == null} style={{width: "24px", height: "24px"}}/></div>
                    <div style={{display: "flex", flex: 1, padding: "5px", borderWidth: "1px", borderLeft: "0px", borderColor: "black", borderStyle: "solid"}} key={u.name}>{u.name}</div>
                    <div style={{display: "flex", width: "70px", padding: "5px", borderWidth: "1px", borderColor: "black", borderStyle: "solid"}} key={u.name+"_count"}>{u.count}</div>
                </div>
            ),
        );

        dateRange = (
            <div style={{display: "flex", flexDirection : "row", flex: 1, paddingLeft: 20, paddingRight: 20}}>
              <div>{top10.startDate}</div>
              <div style={{display: "flex", flex: 1, justifyContent: "center"}}> TO </div>
              <div>{top10.endDate}</div>
          </div>
        );
    }else
    {
        top10List= (
            <Spin tip="Loading Scoreboard Results..."></Spin>
        );
        dateRange = (
            <Spin tip="Loading Dates..."></Spin>
        );
    }

    return (
      <div style={{display: "flex", flexDirection : "column", flex: 1}}>
          {top10List}
          {dateRange}
          <div style={{display: "flex", flexDirection : "row", flex: 1}}>
              <Button disabled={weekOffset>=6} onClick={()=>lastWeek()}>Previous Week</Button>
              <div style={{display: "flex", flex: 1}}> </div>
              <Button disabled={weekOffset<=0} onClick={()=>nextWeek()}>Next Week</Button>
          </div>
      </div>)
};

export default PoodlTop10;