import React, { useRef, useEffect, useState } from 'react';
import { Drawer, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Stage, Layer, Image, Transformer, Circle, Path, Text } from 'react-konva';
import { saveAs } from 'file-saver';
import useImage from 'use-image';

import { canShare, isMobile } from '../../utils/utils';
import Colors from '../../Colors';

const WIDTH = isMobile() ? window.innerWidth - 20 : 600;
const HEIGHT = isMobile() ? window.innerHeight - 150 : 600;
const CHARS_PER_LINE = isMobile() ? 10 : 18;
const FONT_SIZE = 45;

const Wrapper = styled.div`
  border-radius: 15px;
  background-color: #444;
  box-shadow: 0px 0px 80px #222;
  border: 2px solid #555;
  overflow: hidden;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  position: relative;
`;

const DELETE_SVG = `M10.24,1.08v.66a.39.39,0,0,1-.36.36H1.12a.39.39,0,0,1-.36-.36V1.08A.39.39,
0,0,1,1.12.72H3.64L3.82.3A.52.52,0,0,1,4.24,0h2.4a.61.61,0,0,1,.48.3L7.3.72H9.82C10.06.78,10.24.9,10.24,
1.08ZM1.42,2.82h8.1V9.91a1.05,1.05,0,0,1-1,1H2.44a1.05,1.05,0,0,1-1-1ZM3.1,9.19a.39.39,0,0,0,
.36.36.39.39,0,0,0,.36-.36V4.44a.39.39,0,0,0-.36-.36.39.39,0,0,0-.36.36Zm2,0a.36.36,0,0,0,
.72,0V4.44a.36.36,0,1,0-.72,0Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,0,0-.72,0Z`;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

const Col = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

const Explain = styled.div`
  font-size: 12px;
  font-style: italic;
  text-align: center;
  color: #aaa;
`;

const Resources = styled.div`
  padding: 10px;
`;
const Resource = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: calc(100% - 10px);
  background-repeat: no-repeat;
  background-color: #444;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  height: 100px;
  width: 100px;
  margin: 5px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #555;
    border: 2px solid ${Colors.primary[0]};
    transform: translateY(-5px);
  }
`;

const TextWrap = styled.div`
  position: absolute;
  bottom: 0px;
  width: calc(100% - 40px);
  margin: 20px;
`;

const Textarea = styled.textarea`
  color: white;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
  background: transparent;
  border: none;
  font-weight: bold;
  font-size: ${FONT_SIZE}px;
  line-height: 1;
  resize: none;
  width: 100%;
  text-align: center;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`;

const resources = ['head', 'body', 'sticker', 'cyborg'];

const MemeImage = ({ shapeProps, onChange, url, isSelected, onSelect, onDelete }) => {
  const [image] = useImage(url);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        image={image}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
          });
        }}
      />
      {isSelected && (
        <Transformer ref={trRef} rotationSnaps={[0, 90, 180, 270]}>
          <Circle
            fill="red"
            radius={10}
            onClick={onDelete}
            onTap={onDelete}
            shapeProps={{
              x: 200,
            }}
          />
          <Path data={DELETE_SVG} fill="white" x={-6} y={-5} />
        </Transformer>
      )}
    </>
  );
};

const TextArea = ({ value, setValue }) => {
  const lines = value.split('\n');
  let maxLength = CHARS_PER_LINE * 2;
  if (lines.length > 1) {
    maxLength = lines[0].length + CHARS_PER_LINE;
  }

  return (
    <TextWrap>
      <Textarea
        placeholder="Add Text..."
        rows={2}
        maxLength={maxLength}
        onChange={e => {
          let found = false;
          const newText = e.target.value.split('').reduce((arr, curr) => {
            if (curr === '\n') {
              if (!found) {
                arr.push(curr);
              }

              found = true;
            } else {
              arr.push(curr);
            }

            return arr;
          }, []);

          setValue(newText.join(''));
        }}
        value={value}
      />
    </TextWrap>
  );
};

const createImage = ({ url, x = 100, y = 100, height = 200, width = 200 }) => ({
  x,
  y,
  width,
  height,
  id: new Date().getTime(),
  url,
});

const MemeGenerator = () => {
  const [selectedId, selectImage] = useState(null);
  const [meme, setMeme] = useState([]);
  const [background, setBackground] = useState(null);
  const [text, setText] = useState('');
  const [download, setDownload] = useState(false);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    // When meme is updated, check if we have a background layer
    if (background && !meme.find(i => i.id === background.id)) {
      setBackground(null);
    }
  }, [meme]);

  useEffect(() => {
    if (download) {
      document.querySelector('.konvajs-content canvas').toBlob(blob => {
        if (canShare()) {
          const file = new File([blob], 'poodl-meme.png', { type: 'image/png' });

          navigator
            .share({
              text: 'Check out PoodlToken!',
              files: [file],
              title: 'PoodlToken',
              url: 'https://www.poodltoken.com',
            })
            .then(() => console.log('Share was successful.'))
            .catch(error => console.log('Sharing failed', error));
        } else {
          saveAs(blob, 'poodl-meme.png');
        }
      });

      setDownload(false);
    }
  }, [download]);

  const resourcesDom = (
    <Resources>
      <Title>Resources</Title>
      <Explain>(Click to Add)</Explain>
      {resources.map(r => {
        // eslint-disable-next-line
        const url = require(`../../assets/images/meme/${r}.png`).default;
        return (
          <Resource
            key={r}
            src={url}
            onClick={() => {
              setMeme([...meme, createImage({ url })]);
            }}
          />
        );
      })}
    </Resources>
  );

  return (
    <Outer
      onClick={e => {
        if (e.target.nodeName !== 'CANVAS') {
          selectImage(null);
        }
      }}
    >
      {!isMobile() && resourcesDom}
      {isMobile() && (
        <Drawer
          onClose={() => setDrawer(false)}
          visible={drawer}
          bodyStyle={{ padding: '30px 10px 0px 10px' }}
          width={150}
        >
          {resourcesDom}
        </Drawer>
      )}
      <Col>
        <Wrapper>
          <Stage
            width={WIDTH}
            height={HEIGHT}
            onMouseDown={e => {
              const clickedOnEmpty = e.target === e.target.getStage();
              if (clickedOnEmpty) {
                selectImage(null);
              }
            }}
          >
            <Layer>
              {meme.map((img, i) => {
                return (
                  <MemeImage
                    key={i}
                    url={img.url}
                    shapeProps={img}
                    isSelected={img.id === selectedId}
                    onSelect={() => {
                      selectImage(img.id);
                    }}
                    onChange={newAttrs => {
                      const clonedState = meme.slice();
                      clonedState[i] = newAttrs;
                      setMeme(clonedState);
                    }}
                    onDelete={() => {
                      const clonedState = meme.slice();
                      clonedState[i] = null;
                      setMeme(clonedState.filter(e => !!e));
                    }}
                  />
                );
              })}
              {download && (
                <Text
                  fontSize={FONT_SIZE}
                  fontFamily="Inter"
                  fill="white"
                  shadowEnabled={true}
                  shadowBlur={20}
                  shadowColor="black"
                  align="center"
                  fontStyle="bold"
                  draggable
                  text={text}
                  x={0}
                  y={HEIGHT - 122}
                  wrap="word"
                  width={WIDTH}
                />
              )}
            </Layer>
          </Stage>
          {!download && <TextArea value={text} setValue={setText} />}
          {!background && (
            <Upload
              {...{
                name: 'file',
                onChange(info) {
                  const URL = window.webkitURL || window.URL;
                  const url = URL.createObjectURL(info.file.originFileObj);
                  const backgroundImage = createImage({ url, height: HEIGHT, width: WIDTH, x: 0, y: 0 });
                  setBackground(backgroundImage);
                  setMeme([backgroundImage, ...meme]);
                },
              }}
            >
              <Button
                icon={<UploadOutlined />}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginLeft: -100,
                  width: 200,
                }}
                type="danger"
              >
                Add Background
              </Button>
            </Upload>
          )}
        </Wrapper>
        <Button.Group style={{ marginTop: 10, display: 'flex' }}>
          {isMobile() && (
            <Button
              key="drawer"
              type="default"
              size="large"
              style={{ marginRight: 10, flex: 1 }}
              onClick={() => {
                setDrawer(true);
              }}
            >
              Add POODL
            </Button>
          )}
          <Button
            key="clear"
            type="default"
            size="large"
            style={{ marginRight: 10, flex: 1 }}
            onClick={() => {
              setMeme([]);
              setText('');
            }}
            disabled={meme.length === 0 && text.length === 0}
          >
            Clear
          </Button>
          <Button
            key="download"
            type="primary"
            size="large"
            disabled={meme.length === 0}
            style={{ flex: 1 }}
            onClick={() => {
              setDownload(true);
            }}
          >
            Download
          </Button>
        </Button.Group>
      </Col>
    </Outer>
  );
};

export default MemeGenerator;
