import React from 'react';
import { copy } from '../../utils/utils';
import { Button, message } from 'antd';
import {DONATION_ADDRESS} from '../token/Config';
import charityImg1 from '../../assets/images/charity/1.jpg';
import charityImg2 from '../../assets/images/charity/2.jpg';
import charityImg3 from '../../assets/images/charity/3.jpg';

const CharityPage = () => {


    return (
        <div id="charity" style={{ marginTop: 50 }}>
            <div className="container"  style={{ fontSize: '3vw', alignContent: 'center', textAlign: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', width: '100vw' }}>
                <div style={{fontSize: '8vw'}}>
                    Poodl Charity 
                </div>
                <p style={{ textAlign: 'left', marginTop: 20 }}>
                    Poodl is committed to helping those in need. The community as a whole, wants to help any cause that can help dogs and humans have better lives. 
                    This section of our site will grow over time, so please come back often to check progress!
                </p>
                <p style={{ textAlign: 'center', marginTop: 20 }}>Charity Wallet Address: <br/>
                    {DONATION_ADDRESS}<br/>
                    
                    <Button type="primary" style={{ width: '20vw'}} onClick={() => {
                        copy(DONATION_ADDRESS);
                        message.info('Copied!');
                    }}>
                        Copy
                    </Button>
                </p>
                
            </div>
            <div className="container" style={{ fontSize: '3vw', alignContent: 'center', textAlign: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', width: '100vw' }}>
                <div style={{alignContent: 'center', fontSize: '8vw'}}>
                    Our Efforts So Far
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', marginTop: 30}}>
                    <iframe style={{
                        width: '80vw',
                        height: '40vw', 
                        margin: 'auto'}} src="https://www.youtube.com/embed/NxKdd7_x_CQ?start=177" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                    <div style={{ marginTop: 20, fontSize: '6vw'}}>Canines for Disabled Kids</div>
                    <div style={{ marginTop: 20, textAlign: 'left'}}>
                        <a href='https://caninesforkids.org/'>Canines for Disabled Kids</a> is Poodl's first and primary charity. Their mission is increasing independence for children with disabilities and their families by promoting service dog partnerships, 
                        understanding and awareness throughout the community.
                    </div>
                </div>
                <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{fontSize: '6vw'}}>Charity Collaborations</div>
                    <iframe style={{
                        width: '80vw',
                        height: '40vw', 
                        margin: 'auto'}} src="https://www.youtube.com/embed/hbYDjDLwiho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
                <div style={{marginTop: 20, display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '6vw'}}>Independent Community Efforts</div>
                    <div style={{ marginTop: 20, textAlign: 'left', display: 'flex', flexDirection: 'column'}}>
                        <div>
                            Poodl's community members make a difference on their own! Some of our community members will occasionally seek out small charities and help them out.
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center'}}>
                            <img src={charityImg3} style={{width: '20vw', margin: 'auto'}}/>
                            <img src={charityImg1} style={{width: '20vw', margin: 'auto'}}/>
                            <img src={charityImg2} style={{width: '20vw', margin: 'auto'}}/>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 20, display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '6vw'}}>Charity Is Built In!</div>
                    <div style={{ marginTop: 20, textAlign: 'left'}}>
                        Poodl's tokenomics now includes charity as part of the token's core functionality. With every transaction made with Poodl, 0.33% goes directly to charity. 
                    </div>
                </div>
                <div style={{marginTop: 20, display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '6vw'}}>Have A Charity That Wants To Join?</div>
                    <div style={{ marginTop: 20, textAlign: 'left'}}>
                        If any charities or organizations want to get involved, you can reach us on twitter or telegram using the links at the bottom of this page. If we get many requests, we will add a form to make it easier.
                    </div>
                </div>
            </div>
        </div>
    );
  };

export default CharityPage;