import { Spin, Button, Modal, Input, Alert } from 'antd';
import React, { useState } from 'react';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { isMobile } from '../../utils/utils';

import Image from './Image';
import { walletlink, injected, walletConnect } from '../token/Connectors';

const ConnectButton = ({ setConnectModal, setLoading, loading, connector, icon, label }) => {
  const { activate, error } = useWeb3React();

  return (
    <div style={{ padding: 5 }}>
      <Button
        icon={
          <Image
            src={require(`../token/icons/${icon}.png`).default}
            style={{ height: 22, left: 20, position: 'absolute' }}
          />
        }
        size="large"
        style={{ position: 'relative', width: '100%' }}
        disabled={loading}
        onClick={() => {
          setLoading(true);
          activate(connector);
          setLoading(false);
          if (!error) {
            setConnectModal(false);
          }
        }}
      >
        {label}
      </Button>
    </div>
  );
};

const WalletConnect = ({ buttonStyle, children }) => {
  const [loading, setLoading] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const { deactivate, account, error } = useWeb3React();
  const [walletID, setWalletID] = useState(null);

  const buttonProps = {
    setLoading,
    loading,
    setConnectModal,
  };

  const effectiveWalletID = account || walletID;

  let errorDisplay = error && 'Failed to connect wallet';
  if (error && error.name === 'UnsupportedChainIdError') {
    errorDisplay = 'Chain not supported - Make sure your wallet is set to Ethereum Mainnet';
  }

  return (
    <>
      {children({ walletID: effectiveWalletID })}
      <Modal title={<b>Connect Wallet</b>} visible={connectModal} onCancel={() => setConnectModal(false)} footer={null}>
        {loading && <Spin style={{ paddingBottom: 20, margin: 'auto' }} />}
        {error && (
          <Alert style={{ marginBottom: 20 }} message={<span style={{ color: 'black' }}>{errorDisplay}</span>} />
        )}
        <ConnectButton {...buttonProps} connector={walletlink} icon="cb" label="Coinbase Wallet" />
        {!isMobile() && <ConnectButton {...buttonProps} connector={injected} icon="mm" label="MetaMask" />}
        <ConnectButton {...buttonProps} connector={walletConnect} icon="wc" label="WalletConnect" />
        <div style={{ padding: 10, textAlign: 'center' }}>or</div>
        <Input
          placeholder="Enter your Wallet ID manually..."
          style={{ marginBottom: 20 }}
          onChange={e => setWalletID(e.target.value)}
          value={walletID}
        />

        <p style={{ fontSize: 12, marginTop: 5, lineHeight: 1.4 }}>
          * Only required to get your $POODL balance. Wallet IDs are already of public domain, we do not track or store
          your wallet.
        </p>
      </Modal>
      {effectiveWalletID && (
        <Button
          style={buttonStyle}
          onClick={() => {
            if (account) {
              deactivate();
            } else {
              setWalletID(null);
            }
          }}
          type="primary"
          size="large"
        >
          Disconnect Wallet
        </Button>
      )}
      {!effectiveWalletID && (
        <Button style={buttonStyle} onClick={() => setConnectModal(true)} type="primary" size="large">
          Connect Wallet
        </Button>
      )}
    </>
  );
};

const Wrapper = props => {
  return (
    <Web3ReactProvider
      getLibrary={provider => {
        const library = new Web3Provider(provider);
        library.pollingInterval = 8000;
        return library;
      }}
    >
      <WalletConnect {...props} />
    </Web3ReactProvider>
  );
};

export default Wrapper;
