const Colors = {
  light: ['#ECEDED', '#9CA9B3', '#717D86'],
  dark: ['#151719', '#25282C', '#33363A'],
  primary: ['rgb(255, 0, 122)', '#ACADFF', '#F17741', '#E9E9FF'],
  secondary: ['#24E5AF', '#73EFCC', '#1CB68B', '#D2F9EE'],
  alert: {
    error: '#FF6171',
    warning: '#FFA173',
    success: '#24E5AF',
  },
};

export default Colors;
