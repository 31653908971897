import React from 'react';
import PoodlTop10 from './top10';

const PoodlympicsPage = () => {


  return (
    <div style={{display:"flex", flex: 1, justifyContent: "center", flexDirection:"column"}}>
        <div style={{display: "flex", justifyContent: "center", fontSize: "32px", color: "#FFFFFF"}}>
            Poodlympics Top 10
        </div>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto", flexDirection: "column", borderRadius: 15, width: '400px', justifyContent: "center", padding: '30px', backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
            <div style={{display: "flex", flexDirection : "row", marginBottom: "20px"}} >
                <div style={{display: "flex", flex: 2, justifyContent: "left"}}>Username</div>
                <div style={{display: "flex", flex: 1, justifyContent: "left"}}>Score</div>
            </div>
            <PoodlTop10/>
        </div>
    </div>
  );
};

export default PoodlympicsPage;
