import React from 'react';
import GenericSection from './GenericSection';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

import approved from '../../assets/images/approved.png';
import locked from '../../assets/images/lock.png';
import { useTranslation, Trans } from 'react-i18next';
import { AUDIT, LOCKED } from '../token/Config';

const Audit = () => {
  const { t } = useTranslation();

  const sectionHeader = {
    title: t('audit'),
  };
  const sectionHeaderLocked = {
    title: t('lockedLp'),
  };

  return (
    <GenericSection id="audit">
      <div class="column-locked">
        <div>
          <SectionHeader data={sectionHeaderLocked} className="center-content" style={{ paddingBottom: '20px' }} />
        </div>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Image src={locked} alt="Locked Liquidity" style={{ marginRight: 10, height: '140px', width: '140px' }} />
          <div style={{ padding: 40 }}>
            <Trans i18nKey="unicryptLocked" components={{ a: <a />, br: <br /> }} />
          </div>
          <a href={LOCKED}>
            <Button>View Locked Liquidity</Button>
          </a>
        </div>
      </div>
      <div class="column-audit">
        <div>
          <SectionHeader data={sectionHeader} className="center-content" style={{ paddingBottom: '20px' }} />
        </div>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Image src={approved} alt="POODL" style={{ marginRight: 10, height: '140px', width: '140px' }} />
          <div style={{ padding: 40 }}>
            <Trans i18nKey="contractAudit" components={{ a: <a />, br: <br /> }} />
          </div>
          <a href={AUDIT}>
            <Button>View Full Audit</Button>
          </a>
        </div>
      </div>
    </GenericSection>
  );
};

export default Audit;
