import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';

import { injected, walletConnect } from './Connectors';
import Modal from '../elements/Modal';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { useTranslation } from 'react-i18next';


const getLibrary = provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

const ConnectButton = ({ loading, connector, icon, label }) => {
  const { activate } = useWeb3React();

  return (
    <div style={{ padding: 5 }}>
      <Button
        style={{ width: '100%', position: 'relative' }}
        disabled={loading}
        onClick={() => {
          activate(connector);
        }}
      >
        <div style={{ display: 'flex' }}>
          {label}{' '}
          <Image src={require(`./icons/${icon}.png`).default} style={{ height: 25, left: 20, position: 'absolute' }} />
        </div>
      </Button>
    </div>
  );
};

const fetchActivationCode = ({ account }) => {
    return fetch(`https:////poodlapi.xlmoose.com/api/activation/wallet/${account}`).then(r => r.text());
    // return fetch(`https://localhost:5001/api/activation/wallet/${account}`).then(r => r.text());
  };
  
  const getActivationCode = ({ account }) => {
    return fetchActivationCode({ account }).then(r => {
      // Failed, schedule again
      console.log
      if (r.status === '0') {
        return new Promise(resolve => {
          setTimeout(() => {
            fetchActivationCode({ account }).then(r => resolve(r));
          }, 6000);
        });
      } else {
        console.log("activation code:", r);
        return r;
      }
    });
  };

const WalletActivation = () => {
  const { deactivate, account } = useWeb3React();
  const [connectModal, setConnectModal] = useState(false);
  const [activationCode, setActivationCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (account) {
      setLoading(true);
      getActivationCode({ account })
      .then(b => {
      
        setConnectModal(false);

        if (b.status === '0') {
          setError(true);
          return false;
        }else {
            console.log("Wallet address " + account + " connected.");
            setActivationCode(b);
            return true;
        }
      })
      .then(proceed => {
        setLoading(false);
      });
    }
  }, [account]);

  return (
    <div style={{ textAlign: 'center', position: 'relative' }}>
      <Modal show={connectModal} handleClose={() => setConnectModal(false)}>
        <h4>Connect Wallet</h4>
        {loading && <Image src={require('./loading.gif').default} style={{ paddingBottom: 20, margin: 'auto' }} />}
        {error && <div style={{ textAlign: 'right' }}>Failed to Connect to Wallet :(</div>}
        <ConnectButton loading={loading} connector={injected} icon="mm" label="MetaMask" />
        <ConnectButton loading={loading} connector={walletConnect} icon="wc" label="WalletConnect" />
        <p style={{ fontSize: 12, marginTop: 5, lineHeight: 1.4 }}>
          * Only required to get your Wallet ID. Wallet IDs are already of public domain, we do not track or store
          anything else.
        </p>
      </Modal>
      {error && <div style={{ textAlign: 'right' }}>Failed to Connect to Wallet :(</div>}
      {!account && (
        <Button
          style={{ backgroundColor: '#fcbf49', color: 'black', marginTop: -20, width: 240, position: 'relative' }}
          onClick={() => {
            setError(false);
            setConnectModal(true);
          }}
        >
          {"Get Activation Code"}
        </Button>
      )}
      {account && (
        <div>
            <div>Activation Code:</div>
            <div style={{marginBottom: "20px"}} >{activationCode}</div>
            <Button
            style={{ position: 'relative', marginTop: 40 }}
            onClick={() => {
                setConnectModal(false);
                setError(false);
                deactivate();
            }}
            >
            Disconnect Wallet
            </Button>
        </div>
      )}
    </div>
  );
};

const ActivationWrapper = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletActivation />
    </Web3ReactProvider>
  );
};

export default ActivationWrapper;
