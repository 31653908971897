import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactFlow from 'react-flow-renderer';
import { InputNumber, Timeline } from 'antd';
import { isMobile } from '../../utils/utils';
import GenericSection from './GenericSection';

const Header = styled.h3``;

const NodeLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const NodeValue = styled.div`
  padding-top: 10px;
  font-size: 14px;
`;

const Reward = styled.span`
  font-weight: bold;
`;

const TimelineWrap = styled.div`
  margin-top: 40px;
  .ant-timeline-item-tail {
    border-color: #eee;
  }
  .ant-timeline-item.ant-timeline-item-left {
    margin-bottom: 0px !important;
  }
`;

const formatNumber = ({ value, prefix }) => [prefix, value.toLocaleString()].join(' ');
const formatInput = ({ value, prefix }) => [prefix, `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')].join(' ');

const Number = props => {
  const { prefix } = props;
  return (
    <InputNumber
      style={{ color: 'black', width: '100%', textAlign: 'center', marginTop: 5 }}
      formatter={value => formatInput({ value, prefix })}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      {...props}
    />
  );
};

const BURN_TOKENOMICS = 1;
const LIQUIDITY_TOKENOMICS = 0.33;
const MARKETING_TOKENOMICS = 0.33;
const CHARITY_TOKENOMICS = 0.33;
const REDISTRIBUTION_TOKENOMICS = 1;

const WhitePaper = () => {
  const TOTAL = 100e12;
  const [buyAmount, setBuyAmount] = useState(10e9);
  const [holder1Amount, setHolder1Amount] = useState(1e9);
  const [holder2Amount, setHolder2Amount] = useState(0.5e9);

  const isMob = isMobile();

  const targetProps = {
    targetPosition: 'left',
    type: 'output',
    style: {
      border: 'none',
      background: '#ccc',
      width: 250,
    },
  };

  const liquidity = Math.floor((LIQUIDITY_TOKENOMICS * buyAmount) / 100);
  const charity = Math.floor((CHARITY_TOKENOMICS * buyAmount) / 100);
  const marketing = Math.floor((MARKETING_TOKENOMICS * buyAmount) / 100);
  const burn = Math.floor((BURN_TOKENOMICS * buyAmount) / 100);
  const redistribution = Math.floor((REDISTRIBUTION_TOKENOMICS * buyAmount) / 100);
  const otherHolderAmount = Math.floor(TOTAL - holder1Amount - holder2Amount);

  const elements = [
    {
      id: 'buyer',
      type: 'input',
      sourcePosition: 'right',
      data: {
        label: (
          <div>
            <NodeLabel>New Buyer</NodeLabel> buys
            <Number value={buyAmount} onChange={setBuyAmount} />
          </div>
        ),
      },
      style: {
        border: 'none',
        background: '#e9c46a',
        width: 250,
      },
      position: { x: 0, y: 45 },
    },
    {
      id: 'burn',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Burn Wallet ({BURN_TOKENOMICS}%)</NodeLabel>
            <NodeValue>{formatNumber({ value: burn })}</NodeValue>
          </div>
        ),
      },
      position: { x: 320, y: 15 },
    },
    {
      id: 'liquidity',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Liquidity Wallet ({LIQUIDITY_TOKENOMICS}%)</NodeLabel>
            <NodeValue>{formatNumber({ value: liquidity })}</NodeValue>
          </div>
        ),
      },
      position: { x: 320, y: 100 },
    },
    {
      id: 'charity',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Charity Wallet ({CHARITY_TOKENOMICS}%)</NodeLabel>
            <NodeValue>{formatNumber({ value: charity })}</NodeValue>
          </div>
        ),
      },
      position: { x: 320, y: 195 },
    },
    {
      id: 'marketing',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Marketing Wallet ({MARKETING_TOKENOMICS}%)</NodeLabel>
            <NodeValue>{formatNumber({ value: marketing })}</NodeValue>
          </div>
        ),
      },
      position: { x: 320, y: 290 },
    },
    {
      id: 'redist',
      ...targetProps,
      sourcePosition: 'right',
      type: null,
      data: {
        label: (
          <div>
            <NodeLabel>Redistribution ({REDISTRIBUTION_TOKENOMICS}%)</NodeLabel>
            <NodeValue>{formatNumber({ value: redistribution })}</NodeValue>
          </div>
        ),
      },
      position: { x: 320, y: 385 },
      style: {
        ...targetProps.style,
        color: 'white',
        background: '#2a9d8f',
      },
    },

    {
      id: 'holder1',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>John POODL</NodeLabel> holds
            <Number value={holder1Amount} onChange={setHolder1Amount} />
            <NodeValue>
              and is rewarded <Reward>{formatNumber({ value: Math.floor((redistribution * holder1Amount) / TOTAL) })}</Reward>
            </NodeValue>
          </div>
        ),
      },
      position: { x: 680, y: 20 },
      style: {
        ...targetProps.style,
        color: 'white',
        background: '#2a9d8f',
      },
    },
    {
      id: 'holder2',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Jane POODL</NodeLabel> holds
            <Number value={holder2Amount} onChange={setHolder2Amount} />
            <NodeValue>
              and is rewarded <Reward>{formatNumber({ value: Math.floor((redistribution * holder2Amount) / TOTAL) })}</Reward>
            </NodeValue>
          </div>
        ),
      },
      style: {
        ...targetProps.style,
        color: 'white',
        background: '#2a9d8f',
      },
      position: { x: 680, y: 140 },
    },
    {
      id: 'holders',
      ...targetProps,
      data: {
        label: (
          <div>
            <NodeLabel>Other Holders </NodeLabel>
            <NodeValue>
              Distribute
              <Reward>{formatNumber({ value: Math.floor((redistribution * otherHolderAmount) / TOTAL) })}</Reward>
            </NodeValue>
          </div>
        ),
      },
      style: {
        ...targetProps.style,
        color: 'white',
        border: '1px dotted #eee',
        background: 'transparent',
      },
      position: { x: 680, y: 260 },
    },
    { id: 'buyer-burn', source: 'buyer', target: 'burn', animated: true },
    { id: 'buyer-charity', source: 'buyer', target: 'charity', animated: true },
    { id: 'buyer-marketing', source: 'buyer', target: 'marketing', animated: true },
    { id: 'buyer-liquidity', source: 'buyer', target: 'liquidity', animated: true },
    { id: 'buyer-redist', source: 'buyer', target: 'redist', animated: true },
    { id: 'redist-h1', source: 'redist', target: 'holder1', animated: true },
    { id: 'redist-h2', source: 'redist', target: 'holder2', animated: true },
    { id: 'redist-h', source: 'redist', target: 'holders', animated: true },
  ];

  return (
    <GenericSection>
      <h1 style={{ textAlign: 'center' }}>The POODL Whitepaper</h1>
      <Header>What is POODL?</Header>
      <p>
        Another meme coin? Not really. POODL has been around longer than many of the insane amount of tokens launched
        since the meme-coin craze began. However, how we got here is not a pretty story, albeit not an uncommon one...
      </p>
      <Header>A Bit of History</Header>
      <p>
        POODL was created by a group of developers that decided to run the project like most meme-coins, pumped it, dumped it, and
        then abandoned it. This left the community in a rather difficult situation.
      </p>
      <p>
        Most tokens are simply left for dead after the first pump and dump, but POODL survived because the community 
        saw potential in it and together, started a Telegram group to revive it. Pretty quickly people got interested in the project and the community started to grow exponentially.
      </p>
      <p>
        After reviving the token, creating an official site, and all of the necessary infrastructure, the community began to question where to go from here. 
        Liquidity from the original developers was locked until May 2nd, which was weeks away. What could be done to prevent a rug pull? A bridge? No, because the
        original dev could just move along with everyone else and dump on the new network. Eventually it was decided that the best move was to airdrop a new token to 
        our existing holders. It was a risky move, but it was done just in time.
      </p>
      <p>
        On April 25th, every existing holder was airdropped the new token, matching their existing holdings of the old token. The new token was on 
        BSC, partially due to community demand, but also because Eth gas fees were soaring in cost at the time of the transition. BSC was the best solution at the time. 
      </p>
      <p>
        On May 2nd, the original developers returned, as some had feared, and attempted to rug pull the old erc20 token. They almost completely succeeded, save for some liquidity that was 
        provided by community members. This ensured that people still had a way out, if they wished to trade the erc20 token, but it did not impact the new token at all. Everyone was safe at last.
      </p>
      <p>
        Once Poodl was back on solid ground, it was time to begin building utility. Time to grow up and move beyond being a simple meme token. A community voting system was put in place, 
        allowing anyone holding Poodl to vote on important project related issues or initiatives. Then Poodl began seeking out other legitimate projects to partner with. After finding a few, 
        work started on our own utility. 
      </p>
      <p>
        The story continues... 
      </p>
      <h5>A brief history of the most notable meme-coins around</h5>
      <TimelineWrap>
        <Timeline mode="left">
          <Timeline.Item label="Nov. 20, 2020" style={{ fontSize: 20 }}>
            SHIB is deployed
          </Timeline.Item>
          <Timeline.Item label="Feb. 2, 2021" style={{ fontSize: 20 }}>
            <b>POODL is deployed</b>
          </Timeline.Item>
          <Timeline.Item label="Feb. 3, 2021" style={{ fontSize: 20 }}>
            <b>POODL is dumped by previous dev</b>
          </Timeline.Item>
          <Timeline.Item label="Feb. 7, 2021" style={{ fontSize: 20 }}>
            HOGE is deployed
          </Timeline.Item>
          <Timeline.Item label="March. 13, 2021" style={{ fontSize: 20 }}>
            <b>POODL is revived</b>
          </Timeline.Item>
          <Timeline.Item label="April. 25, 2021" style={{ fontSize: 20 }}>
            <b>POODL airdrops new BSC token</b>
          </Timeline.Item>
          <Timeline.Item label="May. 2, 2021" style={{ fontSize: 20 }}>
            <b>POODL erc20 was rugged</b>
          </Timeline.Item>
          <Timeline.Item label="May. 8, 2021" style={{ fontSize: 20 }}>
            <b>Community voting system goes live</b>
          </Timeline.Item>
          <Timeline.Item label="May. 15, 2021" style={{ fontSize: 20 }}>
            <b>LP farming and staking goes live</b>
          </Timeline.Item>
          <Timeline.Item label="July. 19, 2021" style={{ fontSize: 20 }}>
            <b>First wearable + 3D NFT goes live on OpenSea</b>
          </Timeline.Item>
        </Timeline>
      </TimelineWrap>
      <Header>v2.0</Header>
      <p>
        As mentioned, the POODL contract was abandoned, but whoever did it took the keys. This presented limitations
        that put the community at risk, most importantly because when the liquidity unlocks, the rug can be pulled
        leaving all holders stranded. Contract ownership also gives us the ability to implement tokenomics that further
        protect smaller holders from big dumps that affect the price so much.
      </p>
      <p>
        POODL v1.0 was successful only because of the community around it and the restless effort of the admins, who
        work 24/7 to ensure the long-term continuity of this project. In a short span of 1 month, we built all social
        channels, a new website, we were audited, filed a trademark, listed on several exchanges. This gives us the
        confidence to take POODL to the next level in its new version on BSC.
      </p>
      <Header>Tokenomics</Header>
      <p>
        POODL tokenomics encourages holders to keep their tokens by distributing {Math.round(BURN_TOKENOMICS + REDISTRIBUTION_TOKENOMICS + LIQUIDITY_TOKENOMICS + CHARITY_TOKENOMICS + MARKETING_TOKENOMICS)}% of
        every transaction ({REDISTRIBUTION_TOKENOMICS}% distributed, {BURN_TOKENOMICS}% burned, {LIQUIDITY_TOKENOMICS}% liquidity, {CHARITY_TOKENOMICS}% charity, and {MARKETING_TOKENOMICS}% marketing). This
        mechanism decreases the amount of circulating POODL on every transaction and incentivizes holding.
      </p>
      {!isMob && (
        <>
          <p>Use the calculator below to understand and test how this works:</p>
          <div style={{ height: 520 }}>
            <ReactFlow
              elements={elements}
              nodesConnectable={false}
              nodesDraggable={false}
              zoomOnScroll={false}
              panOnScroll={false}
              panOnScrollMode={false}
              zoomOnDoubleClick={false}
            />
          </div>
          <p>
            <b>Note:</b> the calculations above do not include any tax associated to previous transactions. The
            ownership of Jane and John is calculated over the total original supply not including burn.
          </p>
        </>
      )}
      <h3>Original Supply</h3>
      <p>
        POODL v1.0 included a 1% burn tax on transactions and the current total burn is close to 15%. Although POODL
        v2.0 has an original supply of 100T, the current exact burn will be taken right before deployment of v2.0 and
        burnt to start at exactly the same amount.
      </p>
      <Header>Vision and Mission</Header>
      <p>
        After the new team picked up POODL, it immediately became a meme-coin sensation. But we’re not stopping at
        simply being a meme, we have much bigger ambitions.
      </p>
      <p>
        The reality is POODL cannot be sustainable simply based on hype and for it to survive we are building a
        recognizable brand, supported by its community. We all have contributed time and resources to create{' '}
        <Link to="/media">music</Link>, <Link to="/meme">memes</Link>,{' '}
        <a href="https://shop.poodltoken.com">official merchandise</a>,{' '}
        <a href="https://opensea.io/collection/poodl-official-nft-collection">NFTs</a>,{' '}
        <Link to="/face">applications</Link> and much more.{' '}
      </p>
      <p>
        Further, POODL’s goal is not just making the token valuable and its holders monetarily happy, but sharing its
        success with those who need most. We have <Link to="/post/canine-partners">active partnerships</Link> with
        charities which we donate to as POODL grows.
      </p>
      <p>
        POODL is here to stay, if you follow official announcements you will not be disappointed with the constant
        stream of new developments taking place.
      </p>
      <Header>Disclaimer</Header>
      <p>
        <b>Self-Assessment of Sophisticated Purchaser</b>
      </p>
      <p>
        POODL tokens should be undertaken only by individuals, entities, or companies that have significant experience
        with, and understanding of, the usage and intricacies of cryptographic tokens, like Binance Coin (“BNB”), and
        block-chain based software systems. Purchasers should have functional understanding of storage and transmission
        mechanisms associated with other cryptographic tokens. Further purchasers will undertake self-assessment on
        their knowledge and understanding of financial contracts, smart contracts and financial derivatives. Only
        individuals, entities, or companies that understand fully the functionalities, capacities and constrains of the
        POODL token should proceed and purchase it.
      </p>
      <p>
        <b>Price Volatility Disclaimer</b>
      </p>
      <p>
        The POODL tokens do not represent any formal or legally binding investment. Cryptographic tokens that possess
        value in public markets, such as BNB, have demonstrated extreme fluctuations in price over short periods of time
        on a regular basis. Purchasers should be prepared to expect similar fluctuations. Such fluctuations are the
        result of supply and demand forces experienced by token users. By purchasing POODL tokens, users expressly
        acknowledge and represent that they fully understand that the token may experience volatility in pricing and
        will not seek to hold the issuer liable for any losses or any special, incidental, or consequential damages
        arising from, or in any way connected to, the purchase of POODL token.
      </p>
      <p>
        <b>Liability Disclaimer</b>
      </p>
      <p>
        Purchasing POODL token entails a number of risks concerning its valuation, safekeeping and continuous access to
        technical infrastructure (access to Internet, online exchange account, etc.). Users expressly acknowledge and
        represent that they fully understand that the token may experience volatility in pricing, liquidity, technical
        access, data breaches and will not seek to hold the issuer liable for any losses or any special, incidental, or
        consequential damages arising from, or in any way connected to, the purchase of POODL token. As such the issuer
        will not be held liable for any damages that may arise from any lawful actions it undertakes. The lawful actions
        will be determined based on the legislation in force of the jurisdiction of the issuers corporate registration.
      </p>
      <p>
        <b>Taxation Disclaimer</b>
      </p>
      <p>
        When purchasing the POODL token users acknowledges that they fully understand that they are solely responsible
        for any tax reporting and payment, levies or similar dues to tax authorities of their respective jurisdiction.
        Users will not seek to hold the issuer liable for any losses or any special, incidental, or consequential
        damages arising from, or in any way connected to, the purchase of POODL token as far as taxation issues are
        concerned.
      </p>
      <p>
        Users acknowledge that they fully understand that the details of their dealings, accounts and trading history
        information may be shared by the with the tax and law enforcement authorities that request them via legal and
        legitimate means in connection to a criminal activity. · Disclaimer on costs of money transfer All costs of
        money, currencies, crypto assets or others transfers of value are payable by the user.
      </p>
    </GenericSection>
  );
};

export default WhitePaper;

// POODL Feb 2 launch, Feb 3 rug
// HOGE Feb 7
// SHIB Nov 20
// Revived March 13
// DOGE Jan 27
