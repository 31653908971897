import { useQuery } from '@apollo/client';
import { Layout, Spin, Row, Col } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Paper from '../elements/Paper';
import Posts from '../../query/Posts';

const Title = styled.div`
  color: #222;
  font-family: Bangers;
  font-size: 28px;
  white-space: pre-wrap;
  padding-left: 20px;
  padding-top: 20px;
`;

const Date = styled.div`
  font-size: 12px;
  color: #555;
  padding-bottom: 5px;
`;

const Description = styled.div`
  color: #444;
`;

const Article = styled.div`
  height: 100%;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0px;
  padding: 20px;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: rgba(204, 199, 175, 1);
  }
`;

const Cover = styled.div`
  background-image: url(${props => props.src});
  background-color: #222;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
`;

const Header = styled.div`
  color: black;
  font-family: Bangers;
  font-size: 55px;
  text-align: center;
  padding: 40px 0px 10px 0px;
  border-bottom: 2px solid #999;
  margin: 0px 40px 20px 40px;
`;

const Excerpt = styled.div`
  flex: 1;
  font-size: 16px;
  padding: 10px 20px;
`;

const PostList = ({ type = 'News', title = 'The POODL Chronicle' }) => {
  const { data, loading } = useQuery(Posts.GET_ALL, {
    variables: {
      type,
    },
  });

  if (loading)
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        <Spin />
      </div>
    );

  return (
    <Layout style={{ background: 'transparent' }}>
      <Layout.Content style={{ position: 'relative', marginTop: 20 }}>
        <Paper
          style={{
            transform: 'rotateZ(-1deg)',
            top: 0,
            left: '50%',
            width: 1180,
            marginLeft: -600,
            position: 'absolute',
            height: '600px',
            zIndex: 8,
          }}
        />
        <Paper
          style={{
            transform: 'rotateZ(-3deg)',
            top: 0,
            left: '50%',
            width: 1180,
            marginLeft: -600,
            position: 'absolute',
            height: '600px',
            zIndex: 9,
          }}
        />
        <Paper
          style={{
            transform: 'rotateZ(2deg)',
            top: 0,
            left: '50%',
            width: 1180,
            marginLeft: -580,
            position: 'absolute',
            height: '600px',
            zIndex: 9,
          }}
        />
        <Paper style={{ zIndex: 10, position: 'relative', minHeight: '100vh' }}>
          <Header>{title}</Header>
          <Row type="flex" style={{ padding: '0px 20px' }}>
            {data.posts.map(a => (
              <Col key={a.id} xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} style={{ display: 'flex' }}>
                <Link to={`/post/${a.slug}`}>
                  <Article>
                    <Cover src={a.coverImage.url} />
                    <Title>{a.title}</Title>
                    <Excerpt>
                      <Date>{moment(a.date).fromNow()}</Date>
                      <Description>{a.excerpt}</Description>
                    </Excerpt>
                  </Article>
                </Link>
              </Col>
            ))}
          </Row>
        </Paper>
      </Layout.Content>
    </Layout>
  );
};

export default PostList;
