/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useTranslation } from 'react-i18next';

import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

import './i18n/i18n';
import 'antd/dist/antd.less';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import { GRAPHCMS, GRAPHCMS_AUTH_TOKEN } from './components/token/Config';
import Face from './components/token/face/Face';
import Report from './components/token/Report';
import MemePage from './components/meme/MemePage';
import Media from './components/sections/Media';
import Chronicle from './components/sections/Chronicle';
import KnowledgeBase from './components/sections/KnowledgeBase';
import WhitePaper from './components/sections/WhitePaper';
import Drafts from './components/sections/Drafts';
import Post from './components/sections/Post';
import Poodl from './components/sections/Poodl';
import Artist from './components/sections/nfts/Artist';
import Nfts from './components/sections/nfts/Nfts';
import NftPage from './components/sections/nfts/NftPage';
import MigrationHelp from './components/sections/MigrationHelp';
import PoodlympicsPage from './components/poodlympics/poodlympics';
import VRTestPage from './components/vr/aframeTest';
import CharityPage from './components/charity/charityPage';
import TeamPage from './components/team/team';
import GamePage from './components/games/games';
import WalletActivationPage from './components/walletActivation/WalletActivationPage';

const App = () => {
  const childRef = useRef();
  let location = useLocation();
  useTranslation();

  useEffect(() => {
    document.body.classList.add('is-loaded');
    childRef.current.init();
  }, [location]);

  const httpLink = createHttpLink({
    uri: GRAPHCMS,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${GRAPHCMS_AUTH_TOKEN}`,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <>
          <ApolloProvider client={apolloClient}>
            <Switch>
              <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              <Route exact path="/face" component={Face} />
              <AppRoute exact path="/media" component={Media} layout={LayoutDefault} />
              <AppRoute exact path="/report" component={Report} layout={LayoutDefault} />
              <AppRoute exact path="/team" component={TeamPage} layout={LayoutDefault} />
              <AppRoute exact path="/walletActivate" component={WalletActivationPage} layout={LayoutDefault} />
              <AppRoute exact path="/meme" component={MemePage} layout={LayoutDefault} />
              <AppRoute exact path="/poodlympics" component={PoodlympicsPage} layout={LayoutDefault} />
              <AppRoute exact path="/vrtest" component={VRTestPage} layout={LayoutDefault} />
              <AppRoute exact path="/charity" component={CharityPage} layout={LayoutDefault} />
              <AppRoute exact path="/games" component={GamePage} layout={LayoutDefault} />
              <AppRoute exact path="/news" component={Chronicle} layout={LayoutDefault} />
              <AppRoute exact path="/learn" component={KnowledgeBase} layout={LayoutDefault} />
              <AppRoute exact path="/drafts" component={Drafts} layout={LayoutDefault} />
              <AppRoute exact path="/whitepaper" component={WhitePaper} layout={LayoutDefault} />
              <AppRoute exact path="/post/:slug" component={Post} layout={LayoutDefault} />
              <AppRoute exact path="/migration-help" component={MigrationHelp} layout={LayoutDefault} />
              <AppRoute exact path="/nfts" component={Nfts} layout={LayoutDefault} />
              <AppRoute exact path="/nfts/:artistSlug" component={Artist} layout={LayoutDefault} />
              <AppRoute exact path="/artist/:artistSlug" component={Artist} layout={LayoutDefault} />
              <AppRoute exact path="/nft/:id" component={NftPage} layout={LayoutDefault} />
              <Route exact path="/v2.0" component={Poodl} layout={LayoutDefault} />
            </Switch>
          </ApolloProvider>
        </>
      )}
    />
  );
};

export default App;
